import { Component, PLATFORM_ID, Inject, AfterViewInit } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { StorageService } from './shared/services/storage.service';
import { NotificationService } from './shared/components/header/elements/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    public storageService: StorageService,
    public notificationService : NotificationService) {
  }


  ngOnInit() {
    this.requestPermission();
  }

ngAfterViewInit(): void {
  // setTimeout(()=>{
  //   this.requestPermission();
  // },0)
 
}

requestPermission() {
  return new Promise((resolve) => {
      if (!("Notification" in window)) {
          console.log("Notifications are not supported in this browser.");
          resolve("denied");
      } 
      else {
          Notification.requestPermission().then((permission) => {
              if (permission == "granted") {
                  const messaging = getMessaging();
                  onMessage(messaging, (payload) => {
                    this.notificationService.triggerNotification.next(payload)
                    })
                  // Register the service worker before calling getToken
                  navigator.serviceWorker.ready.then(()=>{
                    console.log('fdfdf')
                  })
                //   navigator.serviceWorker.ready.then((registration) => {
                // }).catch((error) => {
                //     console.error("Service worker is not ready: ", error);
                // });

                navigator.serviceWorker.register("/firebase-messaging-sw.js")
                .then((registration) => {
                  let serviceWorker;
              
                  if (registration.installing) {
                    serviceWorker = registration.installing;
                    console.log('Service worker installing');
                  } else if (registration.waiting) {
                    serviceWorker = registration.waiting;
                    console.log('Service worker installed & waiting');
                  } else if (registration.active) {
                    serviceWorker = registration.active;
                    console.log('Service worker active');
                  }
              
                  if (serviceWorker) {
                    console.log("sw current state", serviceWorker.state);
                    if (serviceWorker.state == "activated") {
                      //If push subscription wasn't done yet have to do here
                      console.log("sw already activated - Do whatever needed here");
                      this.getTokenForsubscription(messaging , registration);
                    }
                    serviceWorker.addEventListener("statechange", (e) => {
                      console.log("sw statechange: ", e.target.state);
                      if (e.target.state == "activated") {
                        console.log("Just now activated. Now we can subscribe for push notification");
                        // Subscribe for push notifications here
                        // getToken(messaging, {
                        //   vapidKey: environment.firebase.vapidKey,
                        //   serviceWorkerRegistration: registration,
                        // }).then((currentToken) => {
                        //   if (currentToken) {
                        //     // Ensure correct reference to this.storageService
                        //     this.storageService.store("notificationDeviceToken", currentToken);
                        //     console.log(currentToken);
                        //   } else {
                        //     console.log("No registration token available. Request permission to generate one.");
                        //   }
                        // }).catch((err) => {
                        //   console.log("An error occurred while retrieving token: ", err);
                        // });

                        this.getTokenForsubscription(messaging , registration);
                      }
                    });
                  }

                })
                .catch((err) => {
                  console.error("Error registering the service worker: ", err);
                });

              }
                else if (permission === 'denied') {
                  alert('Notification permission required,Allow Notification from site settings');
                // Do something when permission is denied.
              } else {
                  alert('Notification permission required,Allow Notification from site settings');
                // Do something when permission is dismissed or not available.
              }
          });
      }
  });
}

getTokenForsubscription(messaging , registration){
  getToken(messaging, {
    vapidKey: environment.firebase.vapidKey,
    serviceWorkerRegistration: registration,
  }).then((currentToken) => {
    if (currentToken) {
      // Ensure correct reference to this.storageService
      this.storageService.store("notificationDeviceToken", currentToken);
      console.log(currentToken);
    } else {
      console.log("No registration token available. Request permission to generate one.");
    }
  }).catch((err) => {
    console.log("An error occurred while retrieving token: ", err);
  });
}

}