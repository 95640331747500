import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ResponseModel } from '../../shared/common-model/common.model';
import { GetEventAttendeesRequestModel, GetEventsRequestModel, UpdateEventStatusRequestModel} from './event-list.model';

@Injectable({
  providedIn: 'root'
})
export class EventListService {

  appliedEventListFilters : any;

  constructor(
    private service:DataService
  ) { }

  public notificationEvent = new BehaviorSubject(undefined)


  getEventList(data: GetEventsRequestModel): Observable<ResponseModel> {
    let url = "App/Admin/event/getall";
    const json = JSON.stringify(data);
    console.log(json)
    return this.service.post(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
}

  getEventAttendeesList(data: GetEventAttendeesRequestModel): Observable<ResponseModel> {
    // let url = "App/Event/getAttendees";
    let url = "app/event/getAttendeesWithTicketRedeemStatus";
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
}

//get event details by get by id
getEventDetailsById(id:string): Observable<ResponseModel> {
  let url = `App/Admin/event/${id}/get`;
  return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
          return response;
      })
  );
}

UpdateEventStatus(eventId:string , updateEventStatusRequestModel:UpdateEventStatusRequestModel): Observable<ResponseModel> {
  let url = `App/Admin/event/${eventId}/status/update`;
  const json = JSON.stringify(updateEventStatusRequestModel);
  console.log(json)
  return this.service.post(url, updateEventStatusRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
          return response;
      })
  );
}

}
