import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CitiesResponceModel, GetCitiesRequestModel } from 'src/app/components/master-form-settings/master-form-settings.model';
import { MasterFormSettingsService } from 'src/app/components/master-form-settings/master-form-settings.service';
import { StorageService } from '../../services/storage.service';
import { StoredCityList } from '../../common';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit {

  @Input() dropdownLabel: string = 'Default Label';

  @Input() StausDropDownData:any[]=[];
  @Output() onOptionSelected :EventEmitter<any>  = new EventEmitter<any>();
 
  @Input() selectedOption: any;
  @Input() customDropdownClass: string ='';
  @Input() isMutiselect:boolean = false;
  @Input() showsearchfield:boolean = false;

  @Input() serchValue;
  @Output() serarchValueEmit  : EventEmitter<any>  = new EventEmitter<any>();
  isLoading            : boolean = false;
  getCitiesRequestModel  = new GetCitiesRequestModel()
  cityListData          : CitiesResponceModel[]=[];
  private timerId: any;

  cityList = []

  constructor(private masterFormSettingsService:MasterFormSettingsService , private storageService: StorageService ,){
    this.cityListData = this.storageService.retrieve(StoredCityList);
    this.cityList = this.storageService.retrieve(StoredCityList);


  }
  ngOnInit(): void {
    // this.getCitiesList();
  }


  selectedList:any[]=[];

  selectOption(option) {  
    this.selectedOption = option;
    this.onOptionSelected.emit(option);
  }

  checkIsSelected(option):boolean{
     return ( JSON.stringify(this.selectedList).includes(JSON.stringify(option)))
  }

  onClear(event){
    event?.stopPropagation()
    this.selectOption(undefined)
  }

  searchEvents(event){
    // clearTimeout(this.timerId);
    // this.getCitiesRequestModel.searchText = event;
    // this.getCitiesRequestModel.pageNumber = 1;
    // this.timerId = setTimeout(() => {
    //   this.getCitiesList(true)
    // }, 400);
   clearTimeout(this.timerId);
   this.timerId = setTimeout(() => {
    this.cityListData = this.filterCitiesByInput(this.cityList , this.serchValue )
    }, 500);
   
  }

  filterCitiesByInput(cities: CitiesResponceModel[], input: string): CitiesResponceModel[] {
      const lowercasedInput = input?.toLowerCase();
      return cities?.filter(city =>
        city?.cityName.toLowerCase()?.includes(lowercasedInput) ||
        city?.stateName.toLowerCase()?.includes(lowercasedInput)
      );
    }

  selectStoreOption(id){
    const option = this.cityListData?.find((x)=> x?.cityId == id);
    if(option) this.selectedOption = option;
  }

  

  onScroll() {
    // console.log('onScroll function is called'); 
    // if (!this.isLoading && this.cityListData?.length >=this.getCitiesRequestModel.pageNumber * this.getCitiesRequestModel.pageSize) {
    //   const container = document.querySelector(".auto-scroller");
    //   if (container) {
    //     const maxScroll = container.scrollHeight - container.clientHeight;
    //     const currentScroll = container.scrollTop;
    //     if (maxScroll <= currentScroll + 15 && !this.isLoading) {
    //       this.getCitiesRequestModel.pageNumber += 1;
    //       console.log('cityRequestModel',this.getCitiesRequestModel)
    //       this.getCitiesList();
    //     }
    //   }
    // }
  }

  // getCitiesList(fromSearch?:boolean){
  //   this.masterFormSettingsService.getCities(this.getCitiesRequestModel).subscribe({
  //   next: (res) => {
  //     if(res.isSuccess)
  //     { 
  //       if(fromSearch){
  //         this.cityListData = res?.response?.list; 
  
  //       }else{
  //         this.isLoading = false;
  //         this.cityListData = this.cityListData?.concat(res?.response?.list);
  //       }
  //     }
  //  },
  //   error: (err) => {
  //     console.log(err);
  //      this.cityListData   = [];
  //   }
  //  })
  // }

}