import { DatePipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbDropdown, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import {DateRangeList, DateRangeListForTicketPurchase, DateRangeSelectionForEventCount, utcToLocal} from 'src/app/shared/common';
import { DateRangeSelection, SelectedDateFilterOption } from '../../common-model/common.model';
import { CommonService } from '../../services/common.service';

export class SelectedDates {
	startDate: NgbDate | string;
	endDate: NgbDate | string;
  selectedOption ? : any
}

@Component({
  selector: 'app-range-datpicker',
  templateUrl: './range-datpicker.component.html',
  styleUrls: ['./range-datpicker.component.scss'],
  viewProviders: [ DatePipe ],
  encapsulation: ViewEncapsulation.None,
})
export class RangeDatpickerComponent extends CommonService {
  @Input() isTicketsPurchase: boolean = false;
  @Input() isEventCount : boolean = false;
  @Input() isAllDropdown : boolean = true;
  @Input() convertToUtc : boolean = true;
  dateRangeList      = DateRangeList;
  dateRangeListForEventCount      = DateRangeSelectionForEventCount;
  dateRangeListForTicketPurchase      = DateRangeListForTicketPurchase;
  dateRangeSelection = DateRangeSelection;
  @Input() displayMonths=2;
  @Input() datepickername;
  @Input() inputDate: string = "";
  @Input() showOnlyRangePicker : boolean = false;
  @Input() showOnlyRangePickerforDash : boolean = false;
  @Input() showClearIcon : boolean = false;
  @Input() customPlaceholder : string = ""
  @Input('ngClass') ngClass: NgClass["ngClass"];
  private converter: utcToLocal = new utcToLocal();

  @Output() selectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();
  // @Output() DropdownSelectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();

  appliedStartDate : NgbDate | null;
  appliedEndDate   : NgbDate | null;
  
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  
  hoveredDate: NgbDate | null = null;
  
  fromDate : NgbDate | null;
  toDate   : NgbDate | null;
  @Input() formatMMddyyyy:boolean=false;
  @Input() fromDateFromParent;
  @Input() toDateFromParent;
  @Input() datePickerLabel;
  @Input() isRequired;
  @Input() fromEdit:boolean=false;
  @Input() isMaxDate:boolean=false;
  

  selectedDateFilterOption = new SelectedDateFilterOption();

  editSingleDate :NgbDate | null;
  
  @ViewChild("datepicker") dp: NgbInputDatepicker;
  @ViewChild("dropdown") dropdown: NgbDropdown;
    
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private datePipe: DatePipe,) {
    super();
    // this.onClear();
    this.clearFields();
  }
  convetUtcTolocalDate(date:string){
    return this.converter.convetUtcTolocalDate(date);
  }
  getMaxSelectableDate(): NgbDateStruct {
    const currentDate = new Date();
    const threeMonthsLater = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
    
    return {
      year: threeMonthsLater.getFullYear(),
      month: threeMonthsLater.getMonth() + 1, // Months are zero-based, so add 1
      day: threeMonthsLater.getDate()
    };
  }
  
  ngOnInit(): void {
    console.log(this.dateRangeList);
    this.inputDate = null;
  }


  
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.editSingleDate = date;
    } 
    // else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
    //   this.toDate = date;
    // } 
    else if (this.fromDate && !this.toDate && date && (date.equals(this.fromDate)|| date.after(this.fromDate))) {
      this.toDate = date;
    } 
    else {
      this.toDate = null;
      this.fromDate = date;
    }
    // this.formatAndAssign() // if direct assign and show to uncomment this function
    // if(this.isMaxDate){
    //   this.addThirtyDays();
    // }
  }

  // addThirtyDays() {
  //   // Convert NgbDate to JavaScript Date object
  //   const jsDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
  //   // Add 30 days
  //   jsDate.setDate(jsDate.getDate() + 30);
  //   this.maxDate = {
  //     year: jsDate.getFullYear(),
  //     month: jsDate.getMonth() + 1, // Months are zero-based, so add 1
  //     day: jsDate.getDate()
  //   };
  // }


  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isStartDate(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  isEndDate(date: NgbDate) {
    return date.equals(this.toDate);
  }

  onClear(event?) {
    event?.stopPropagation()
    this.clearFields();
    this.selectedDates.emit({
      startDate: this.editSingleDate,
      endDate: this.toDate
    });
  }

  clearFields(){
    this.inputDate = null;
    this.fromDate = null;
    this.toDate = null;
    this.editSingleDate=null;
    this.selectedDateFilterOption = new SelectedDateFilterOption();
    this.appliedStartDate = null;
    this.appliedEndDate   = null;
    this.maxDate = null;
  }

  formatAndAssign(){
    if(this.fromDate){
      let startDate = this.formatter.format(this.fromDate);
      this.appliedStartDate = Object.assign({}, this.fromDate)
      const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'MM/dd/yyyy':'MM/dd/yyyy');
      this.inputDate = `${datefrom}`;
      if(this.fromEdit){
        this.fromDate = null;
      }
    }

    if(this.fromDate && this.toDate){
      let startDate = this.formatter.format(this.fromDate);
      let endDate = this.formatter.format(this.toDate);
      this.appliedEndDate = Object.assign({}, this.toDate)
      const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'MM/dd/yyyy':'MMMM ,d yyyy');
      const dateTo = this.datePipe.transform(endDate, this.formatMMddyyyy? 'MM/dd/yyyy':'MMMM ,d yyyy');
      this.inputDate = `${datefrom} - ${dateTo}`;
    }console.log("selected Date:", this.inputDate);
    
  }

  formatAndDisplay(value:NgbDate):string{
    const dt = this.formatter?.format(value);
    return this.datePipe?.transform(dt,this.formatMMddyyyy? 'MM/dd/yyyy':'MMMM ,d yyyy');
  }

  

  onApply(isForSelect=false) {
    this.selectedDateFilterOption = {id:DateRangeSelection.Custom , name:'Custom'};
    this.formatAndAssign();
    // if(this.appliedStartDate && !this.appliedEndDate){
    //   const startFilterDate  = this.formatter?.format(this.appliedStartDate);
    //   var startDt =new Date(startFilterDate);
    //   this.selectedDatesPassToParent(this.getStartDateUtc(startDt),this.getEndDateUtc(startDt));
    // }

    if(isForSelect) return;
    if(this.appliedStartDate && this.appliedEndDate){
      const startFilterDate  = this.formatter?.format(this.appliedStartDate);
      const endFilterDate    = this.formatter?.format(this?.toDate);
      const startDt = new Date(startFilterDate);
      const endDt   = new Date(endFilterDate);
      this.selectedDatesPassToParent( this.convertToUtc ? this.getStartDateUtc(startDt):startFilterDate, this.convertToUtc ? this.getEndDateUtc(endDt):endFilterDate);
    }

    setTimeout(() => {
      this.dp.toggle();
    }); 
  }
   
  onDatepickerClosed() {
    this.fromDate = this.appliedStartDate?  Object.assign({},this.appliedStartDate):undefined ;
    this.toDate   = this.appliedEndDate? Object.assign({},this.appliedEndDate):undefined ;
  }

  mouseEnter(){
    if(this.fromEdit){
      this.fromDate = null;
      }
  }

  openDatepicker(event?) {
    event?.stopPropagation();
    if(this.dp?.isOpen()) return;
    console.log('efd');
    this.dp.open();
    if(this.appliedStartDate){
      this.dp.navigateTo(this.appliedStartDate)
    }else{
      const currentDate = new Date();
      this.dp.navigateTo({
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1, // Months are zero-based, so add 1
        day: currentDate.getDate()
      })
    }
  } 

  onFocus(){
    console.log('fo')
  }

  selectedDatesPassToParent(startDate , endDate){
    this.selectedDates.emit({
      startDate: startDate,
      endDate: endDate ? endDate:null ,
      selectedOption : this.selectedDateFilterOption
    });
  }

  onDateFilterChange(selectedOption , dropdownInstace){
    switch(selectedOption?.id){
      case(DateRangeSelection.Today):
        this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const todayDate = this.getTodayFormattedDate();
        this.selectedDatesPassToParent(todayDate?.startDate , todayDate?.endDate)
      break;

      case(DateRangeSelection.Tomorrow): 
      this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const toMorowDate = this.getTomorrowFormattedDate();
        this.selectedDatesPassToParent(toMorowDate?.startDate , toMorowDate?.endDate)
      break;

      case(DateRangeSelection.ThisWeek): 
      this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const thisWeek = this.getThisWeekFormattedDates();
        this.selectedDatesPassToParent(thisWeek?.startDate , thisWeek?.endDate)
      break;

      case(DateRangeSelection.ThisWeekend): 
      this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const thisWeekend = this.getThisWeekendFormattedDates();
        this.selectedDatesPassToParent(thisWeekend?.startDate , thisWeekend?.endDate)
      break;

      case(DateRangeSelection.NextWeek): 
      this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const nextWeek = this.getNextWeekFormattedDates();
        this.selectedDatesPassToParent(nextWeek?.startDate , nextWeek?.endDate)
      break;

      case(DateRangeSelection.ThisMonth): 
        this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const thisMonth = this.getThisMonthFormattedDates();
        this.selectedDatesPassToParent(thisMonth?.startDate , thisMonth?.endDate)
      break;

      case(DateRangeSelection.Yesterday): 
        this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const yesterdayDate = this.getYesterdayFormattedDate();
        this.selectedDatesPassToParent(yesterdayDate?.startDate , yesterdayDate?.endDate)
      break;
      
      case(DateRangeSelection.Last7days): 
        this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const last7Days = this.getLast7DaysFormattedDates();
        this.selectedDatesPassToParent(last7Days?.startDate , last7Days?.endDate)
      break;

      case(DateRangeSelection.Last30days): 
        this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const last30Days = this.getLast30DaysFormattedDates();
        this.selectedDatesPassToParent(last30Days?.startDate , last30Days?.endDate)
      break;

      case(DateRangeSelection.Next7days): 
        this.clearFields();
        this.inputDate = selectedOption?.name;
        this.selectedDateFilterOption = selectedOption;
        const next7Days = this.getNext7DaysFormattedDates();
        this.selectedDatesPassToParent(next7Days?.startDate , next7Days?.endDate)
      break;

      case(DateRangeSelection.Next30days): 
      this.clearFields();
      this.inputDate = selectedOption?.name;
      this.selectedDateFilterOption = selectedOption;
      const next30Days = this.getNext30DaysFormattedDates();
      this.selectedDatesPassToParent(next30Days?.startDate , next30Days?.endDate)
      break;

      case(DateRangeSelection.Custom): 
        dropdownInstace?.close();
        this.openDatepicker();
      break;
    }
    dropdownInstace?.close();
  }


  checkIsSelected(option):boolean{
    return  this.selectedDateFilterOption?.id == option?.id;
 }

 selectStoreDate(selectedOption ,ngbDates){
  if(selectedOption){
    if(selectedOption?.id != DateRangeSelection.Custom){
      this.clearFields();
      this.inputDate = selectedOption?.name;
      this.selectedDateFilterOption = selectedOption;
      this.dropdown?.close();
    } else {

      this.fromDate = ngbDates?.fromDate;
       this.toDate = ngbDates?.toDate ;
       this.onApply(true)
    }

  }
 }

} 