<div class="notification-box d-flex cursor-pointer" (mouseenter)="onHoverGetNotifications()" (click)="toggleNotificationMobile()">
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1454 14.175L17.8704 5.97534C17.3846 4.22133 16.326 2.68022 14.8633 1.59727C13.4005 0.514321 11.6175 -0.048224 9.79804 -0.000834296C7.97861 0.0465554 6.22732 0.701157 4.82288 1.85879C3.41845 3.01642 2.44158 4.61055 2.04775 6.38747L0.288124 14.3062C0.20298 14.6899 0.205057 15.0877 0.294201 15.4704C0.383346 15.8532 0.557283 16.211 0.803179 16.5175C1.04908 16.8241 1.36066 17.0715 1.71494 17.2415C2.06922 17.4115 2.45715 17.4999 2.85012 17.5H6.00625C6.20707 18.489 6.74364 19.3781 7.52502 20.0168C8.30641 20.6555 9.28456 21.0044 10.2937 21.0044C11.3029 21.0044 12.2811 20.6555 13.0625 20.0168C13.8439 19.3781 14.3804 18.489 14.5812 17.5H17.6175C18.0218 17.4998 18.4207 17.4062 18.7829 17.2265C19.1451 17.0468 19.4609 16.7859 19.7057 16.464C19.9505 16.1422 20.1176 15.7681 20.194 15.3711C20.2704 14.974 20.2541 14.5647 20.1463 14.175H20.1454ZM10.2937 19.25C9.75277 19.2477 9.2257 19.0784 8.78464 18.7651C8.34358 18.4519 8.01007 18.01 7.82975 17.5H12.7577C12.5774 18.01 12.2439 18.4519 11.8029 18.7651C11.3618 19.0784 10.8347 19.2477 10.2937 19.25V19.25ZM18.3131 15.4043C18.2315 15.5126 18.1257 15.6002 18.0041 15.6602C17.8826 15.7202 17.7487 15.751 17.6131 15.75H2.85012C2.7191 15.7499 2.58976 15.7205 2.47164 15.6638C2.35352 15.6071 2.24964 15.5246 2.16767 15.4224C2.0857 15.3202 2.02773 15.2009 1.99804 15.0733C1.96835 14.9456 1.96769 14.813 1.99612 14.6851L3.75575 6.76634C4.06581 5.37177 4.83314 4.12087 5.93577 3.21249C7.0384 2.3041 8.41305 1.79037 9.84118 1.75295C11.2693 1.71554 12.669 2.15659 13.8176 3.006C14.9663 3.85541 15.7981 5.06442 16.1808 6.44084L18.4557 14.6405C18.4929 14.7701 18.4993 14.9067 18.4746 15.0392C18.4498 15.1718 18.3946 15.2968 18.3131 15.4043V15.4043Z" stroke="none" fill="#1E1A17"/>
    </svg>
        
  <span *ngIf="notificationBadgeCount" class="badge rounded-pill">{{notificationBadgeCount}}</span>
</div>
<div class="onhover-show-div notification-dropdown d-flex flex-column">
  <h6 class="dropdown-title font-inter-700 text-theme-color-dark m-0">Notifications</h6>
  <ul class="flex-fill overflow-auto">
    <li class="notification-body" [ngClass]="{'is-seen pe-none':item?.isSeen}" *ngFor="let item of notificationList | slice:0:4" (click)="item?.isSeen ? null: markAsRead(item)">
      <p class="notification-body-title">
        <span class="col font-inter-600 text-truncate">{{item.title}}</span>
        <span class="col font-inter-600 text-end cursor-pointer">{{convetUtcTolocalDate(item?.createdAt)}}</span>
      </p>
      <p class="notification-body-subtitle">
        <span class="col text-start font-inter-600 text-truncate">{{item.subTitle}}</span>
        <span class="col font-inter-500 text-end cursor-pointer">{{convetUtcTolocalTime(item?.createdAt)}}</span>
      </p>
      <p class="col notification-Desc font-inter-400">{{item.description}}</p>
    </li>
  </ul>
  <div class="notification-footer-contain" *ngIf="notificationList?.length != 0">
    <p class="d-flex justify-content-between font-inter-600 m-0">
      <span class="notification-footer text-decoration-underline cursor-pointer" (click)="markAllAsRead()">Mark All as Read</span>
      <span class="notification-footer text-decoration-underline cursor-pointer" (click)="openViewAllNotificationDrawer(notificationSidebarComponent?.content)">View All</span>
    </p>
  </div>
  <div class="position-absolute top-50 start-50 translate-middle color-primary-grey text-nowrap" *ngIf="notificationList?.length == 0">New notification not found.</div>

</div>

<app-notification-sidebar (onScroll)="onScroll()" (markAllAsRead)="markAllAsRead()" (markAsRead)="markAsRead($event)" (clickOnNotificationTab)="getNotificationOnTabClick($event)" [notificationsData]="notificationList" *ngIf="openClosedSidebarNotification" #notificationSidebarComponent></app-notification-sidebar>