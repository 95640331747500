<!-- Datpicker Starts -->
<ng-template #datepicker_container>
    <!-- (click)="openDatepicker()"  -->
    <div class="date-input range-datepicker d-flex p-0 w-100" [ngClass]="{'selected': inputDate}" >
        <div class="input-group gap-2" [class]="input-group + ' custom-input-group'" [ngClass]="ngClass" (click)="openDatepicker($event)"> 
            <input  [required]="isRequired" [class]="inputDate-Selection +' input-custom-date-selection '" [ngClass]="ngClass"  [(ngModel)]="inputDate" type="text" [name]="name" class="d-none inputDate-Selection" >
            <!-- <div [ngClass]="ngClass" class="w-100 custom-f-14 text-truncate d-flex align-items-center justify-content-center">
              {{ inputDate ? convertUtcToLocalDateTime(inputDate) : customPlaceholder }}
            </div> -->
            <div [ngClass]="ngClass" class="w-100 custom-f-14 text-truncate d-flex align-items-center justify-content-center">{{inputDate?inputDate :customPlaceholder}}</div>
            <input 
                [required]="isRequired"
                [name]="datepickername" 
                id="datepicker"
                class="input-calendar"
                [class]="input-calendar + ' custom-range-picker filter_active'"
                [placeholder]="customPlaceholder"
                ngbDatepicker
                [value]="inputDate"
                #datepicker="ngbDatepicker" 
                [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event)" 
                (closed)="onDatepickerClosed()"
                [displayMonths]="displayMonths" 
                [dayTemplate]="dayTemplate"
                [footerTemplate]="footerTemplate" 
                [restoreFocus]="true"
                outsideDays="hidden" 
                tabindex="-1"
                autocomplete="off" 
                 />
                 <!-- [maxDate]=" isMaxDate ? maxDate : null" -->
                 <!-- [maxDate]="displayMonths == 1? getMaxSelectableDate():null" -->
                <!-- <hr> -->
                <span *ngIf="inputDate && !showClearIcon"  class="d-flex clear_icon">
                  <!-- *ngIf="fromDate && toDate" -->
                  <svg (click)="onClear($event)"  width="0.813rem" height="0.825rem" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.92193 14.9911C1.55417 15.0128 1.1925 14.8891 0.913167 14.6462C0.362278 14.0856 0.362278 13.1801 0.913167 12.6195L12.9758 0.41642C13.5488 -0.12597 14.4479 -0.0958189 14.9841 0.483822C15.4689 1.00799 15.4971 1.81365 15.0502 2.37123L2.91647 14.6462C2.64075 14.8856 2.28487 15.009 1.92193 14.9911Z" fill="#00091A"/>
                    <path d="M13.9705 14.991C13.5978 14.9894 13.2405 14.8397 12.9759 14.5742L0.913175 2.37107C0.402804 1.76814 0.472191 0.860762 1.06819 0.344406C1.60013 -0.116429 2.38463 -0.116429 2.91652 0.344406L15.0503 12.5475C15.6231 13.09 15.6527 13.9996 15.1164 14.5791C15.0951 14.6022 15.0731 14.6245 15.0503 14.6461C14.7532 14.9074 14.3622 15.0323 13.9705 14.991Z" fill="#00091A"/>
                  </svg>                 
                </span>
                <!-- [startDate]="fromDate!"  -->
            
            <ng-template #dayTemplate let-date let-focused="focused" (mouseleave)="leave()" >
                <span 
                    class="custom-day" 
                    [class.range]="isRange(date)"
                    [class.start-date]="isStartDate(date)" 
                    [class.end-date]="isEndDate(date)"
                    [class.faded]="isHovered(date) || isInside(date)" 
                    (mouseenter)="hoveredDate = date ;mouseEnter()"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
                <!-- [class.focused]="focused"  -->
            </ng-template>


            <ng-template #footerTemplate>
                <hr class="my-0">
                <div class="action-buttons d-flex justify-content-end">
                  <div class="custom-f-14 d-flex"><span >{{formatAndDisplay(fromDate)}}&nbsp;</span><span *ngIf="toDate">-&nbsp;{{formatAndDisplay(toDate)}}</span></div>
                    <button class="btn btn-sm btn-gray text-nowrap cursor-pointer" [disabled]="(!fromDate || !toDate)" (click)="fromDate=null;toDate=null">Clear</button>
                    <button class="btn btn-sm btn-blue text-nowrap cursor-pointer" (click)="onApply()" [disabled]="(!fromDate || !toDate)" >Apply</button> 
                    <!-- [disabled]="!inputDate" -->
                </div>
            </ng-template>

        </div>
    </div>
</ng-template>
<!-- Datepicker Ends -->

<div *ngIf="true && !showOnlyRangePicker && !showOnlyRangePickerforDash" [class]="" class="custom_dropdown_container" [ngClass]="{'selected':inputDate,'date_range_select':(inputDate &&selectedDateFilterOption?.id == dateRangeSelection.Custom)}">
  <!-- (this.fromDate && this.toDate) -->
    <div ngbDropdown class="custom-dropdown" #dropdown="ngbDropdown" >
      <div [class]="'selected-option d-flex align-items-center position-relative'">
        <div class="toggle_show" ngbDropdownToggle>
        </div>
        <ng-container *ngTemplateOutlet="datepicker_container"></ng-container>
      </div>
      
      <div ngbDropdownMenu class="dropdown-options">
        <ul class="first_list f-12">
          <ng-container *ngIf="isAllDropdown; else eventsCountDropdown">
            <!-- Dashboard Dropdown -->
            <li class="px-1" *ngFor="let option of dateRangeList; let last = last" [ngClass]="{'active_option': checkIsSelected(option) }" (click)="onDateFilterChange(option, dropdown)">
              <span class="group-label color-primary-grey" [ngClass]="{'text-theme-color-sapphire-50': last}">
                {{ option?.name }}
                <span *ngIf="last">
                  <svg width="0.5rem" height="0.75rem" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41 -0.0012207L0 1.40878L4.58 5.99878L0 10.5888L1.41 11.9988L7.41 5.99878L1.41 -0.0012207Z" fill="#5663EE"/>
                  </svg>
                </span>
              </span>
            </li>
          </ng-container>
          
          <ng-template #eventsCountDropdown>
            <ng-container *ngIf="isEventCount; else TicketPurchaseDropdown">
              <!-- Events Dropdown -->
              <li class="px-1" *ngFor="let option of dateRangeListForEventCount; let last = last" [ngClass]="{'active_option': checkIsSelected(option) }" (click)="onDateFilterChange(option, dropdown)">
                <span class="group-label color-primary-grey" [ngClass]="{'text-theme-color-sapphire-50': last}">
                  {{ option?.name }}
                  <span *ngIf="last">
                    <svg width="0.5rem" height="0.75rem" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.41 -0.0012207L0 1.40878L4.58 5.99878L0 10.5888L1.41 11.9988L7.41 5.99878L1.41 -0.0012207Z" fill="#5663EE"/>
                    </svg>
                  </span>
                </span>
              </li>
            </ng-container>
          
            <ng-template #TicketPurchaseDropdown>
              <!-- Tickets Purchase Dropdown -->
              <li class="px-1" *ngFor="let option of dateRangeListForTicketPurchase; let last = last" [ngClass]="{'active_option': checkIsSelected(option) }" (click)="onDateFilterChange(option, dropdown)">
                <span class="group-label color-primary-grey" [ngClass]="{'text-theme-color-sapphire-50': last}">
                  {{ option?.name }}
                  <span *ngIf="last">
                    <svg width="0.5rem" height="0.75rem" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.41 -0.0012207L0 1.40878L4.58 5.99878L0 10.5888L1.41 11.9988L7.41 5.99878L1.41 -0.0012207Z" fill="#5663EE"/>
                    </svg>
                  </span>
                </span>
              </li>
            </ng-template>
          </ng-template>
        </ul>
      </div>
    </div>
</div> 

<div *ngIf="true && showOnlyRangePicker && !showOnlyRangePickerforDash">
    <ng-container *ngTemplateOutlet="datepicker_container"></ng-container>
</div>

<div *ngIf="showOnlyRangePickerforDash" class="custom_dropdown_container"  [ngClass]="{'selected':inputDate,'date_range_select':(inputDate &&selectedDateFilterOption?.id == dateRangeSelection.Custom)}">
  <div ngbDropdown class="custom-dropdown" #dropdown="ngbDropdown" >
    <div [class]="'selected-option d-flex align-items-center position-relative datePicker_dropdown'">
      <ng-container *ngTemplateOutlet="datepicker_container"></ng-container>
    </div>
  </div>
</div>

  

<!-- <div *ngIf="showOnlyRangePicker">
  <ng-container *ngTemplateOutlet="datepicker_container"></ng-container>
</div> -->