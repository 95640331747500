import { CommonPagination } from "../../../../../../../src/app/shared/common-model/common.model";


export class Notification {
}

export enum NotificationReadStatusFilter
    {
        All = 0,
        Read = 1,
        Unread = 2,
    }

export class NotificationRequestModel extends  CommonPagination {
    notificationReadStatusFilterId : number = NotificationReadStatusFilter.All;
}


export class NotificationResponseModel extends  CommonPagination {
    id                           :string ;
    isSeen                       :boolean;
    isNotified                   :boolean;
}

export class NotificationIds{
    ids:any;
}

export enum NotificationReferenceIdType
    {
        None = 1,
        Event = 2,
        PurchaseTransaction = 3,
        Ticket = 4,
    }