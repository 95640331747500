import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  @Input() status: boolean;
  @Output() check:EventEmitter<boolean>=new EventEmitter();
  @Output() changed:EventEmitter<any>=new EventEmitter() ;
  @Input() lableName:string;
  @Input() labelClass:string;
  @Input() smallCheckbox:string;
  @Input() dyanamicId:string;
  @Input() readonly:boolean = false;
  @Input() disabled?:boolean = false;
  @Input() isDashed?:boolean = false;
  @Input() isHardCoded?:boolean = false;
  @Input() chekMarkCss?:string="";

}
