<div  class="custom_dropdown_container" [ngClass]="{'selected':(selectedOption || selectedList?.length)}">
    <div ngbDropdown placement="auto" class="custom-dropdown" #dropdown="ngbDropdown" >
      <div [class]="'selected-option d-flex align-items-center '+ customDropdownClass  "  ngbDropdownToggle >
        <ng-container *ngIf="!isMutiselect">
          <span *ngIf="selectedOption == undefined" class="text-truncate">{{ dropdownLabel }}</span>
          <div *ngIf="selectedOption" class="d-flex gap-2 align-items-center text-truncate justify-content-between">
            <span class="text-truncate">{{ selectedOption?.name}}</span>
            <span class="d-flex">
              <svg (click)="onClear($event);" width="0.813rem" height="0.825rem" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.92193 14.9911C1.55417 15.0128 1.1925 14.8891 0.913167 14.6462C0.362278 14.0856 0.362278 13.1801 0.913167 12.6195L12.9758 0.41642C13.5488 -0.12597 14.4479 -0.0958189 14.9841 0.483822C15.4689 1.00799 15.4971 1.81365 15.0502 2.37123L2.91647 14.6462C2.64075 14.8856 2.28487 15.009 1.92193 14.9911Z" fill="#00091A"/>
                <path d="M13.9705 14.991C13.5978 14.9894 13.2405 14.8397 12.9759 14.5742L0.913175 2.37107C0.402804 1.76814 0.472191 0.860762 1.06819 0.344406C1.60013 -0.116429 2.38463 -0.116429 2.91652 0.344406L15.0503 12.5475C15.6231 13.09 15.6527 13.9996 15.1164 14.5791C15.0951 14.6022 15.0731 14.6245 15.0503 14.6461C14.7532 14.9074 14.3622 15.0323 13.9705 14.991Z" fill="#00091A"/>
              </svg>                
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="isMutiselect" >
          <span *ngIf="!selectedList?.length" class="text-truncate">{{ dropdownLabel }}</span>
          <div *ngIf="selectedList?.length" class="d-flex gap-2 align-items-center text-truncate">
            <span class="text-truncate">{{ selectedList[0]?.name}}</span> {{selectedList?.length >1 ? '&nbsp;+'+(this.selectedList?.length - 1) :'' }}
            <span class="d-flex">
              <svg (click)="multiClearSelectedList()" width="0.813rem" height="0.825rem" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.92193 14.9911C1.55417 15.0128 1.1925 14.8891 0.913167 14.6462C0.362278 14.0856 0.362278 13.1801 0.913167 12.6195L12.9758 0.41642C13.5488 -0.12597 14.4479 -0.0958189 14.9841 0.483822C15.4689 1.00799 15.4971 1.81365 15.0502 2.37123L2.91647 14.6462C2.64075 14.8856 2.28487 15.009 1.92193 14.9911Z" fill="#00091A"/>
                <path d="M13.9705 14.991C13.5978 14.9894 13.2405 14.8397 12.9759 14.5742L0.913175 2.37107C0.402804 1.76814 0.472191 0.860762 1.06819 0.344406C1.60013 -0.116429 2.38463 -0.116429 2.91652 0.344406L15.0503 12.5475C15.6231 13.09 15.6527 13.9996 15.1164 14.5791C15.0951 14.6022 15.0731 14.6245 15.0503 14.6461C14.7532 14.9074 14.3622 15.0323 13.9705 14.991Z" fill="#00091A"/>
              </svg>                
            </span>
          </div>
        </ng-container>

      </div>
      <!-- *ngIf="dropdown.isOpen()" -->
          <div ngbDropdownMenu class="dropdown-options">    
            <ul class="first_list f-12">
              <ng-container *ngIf="!isMutiselect">
                <li class="px-1 text-truncate" *ngFor="let option of StausDropDownData" [ngClass]="{'active_option': option == selectedOption }" (click)="selectOption(option);dropdown?.close()">
                  <span class="group-label color-primary-grey">{{ option?.name}}</span>
                </li>
              </ng-container>
              <ng-container *ngIf="isMutiselect">
                <li class="px-1 text-truncate" *ngFor="let option of StausDropDownData" [ngClass]="{'active_option': checkIsSelected(option) }" (click)="selectOptions(option)">
                  <span class="group-label color-primary-grey">{{ option?.name }}</span>
                </li>
              </ng-container>
            </ul>
          </div>
    </div>
</div>