export class ConfirmationPopup {
}


//enum for event confirmation popup

export enum EventConfirmPopUP {
    APPROVED = "Approve",
    REJECT = "Reject",
    PARTIALLY_REJECT = "Partially Reject"
}


//enum for user status popup
export enum UserStatusConfirmPopUP {
    SUSPEND_USER    = "Suspend",
    UN_SUSPEND_USER = "Un-suspend",
    SUSPEND_HOST    = "Suspend Host"
}

//enum for user status popup
export enum ActiveConfirmPopUP {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
}