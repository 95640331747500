<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0 ps-3">
    <!-- <app-search></app-search> -->
    
    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 col-3 p-0">
      <!-- <app-swiper></app-swiper> -->
      <div class="header-logo-wrapper col-auto p-0 d-flex align-items-center">
        <div class="logo-wrapper">
          <a routerLink="">
            <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
          </a>
        </div>
       <div class="toggle-sidebar border-0 p-0" (click)="sidebarToggle()">
          <ng-container *ngIf="navServices.collapseSidebar; else expandedIcon">
            <!-- Show the first icon when collapseSidebar is true -->
            <svg width="3rem" height="3rem" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 22C0 20.8954 0.89542 20 2 20H26C27.1046 20 28 20.8954 28 22C28 23.1046 27.1046 24 26 24H2C0.89542 24 0 23.1046 0 22ZM0 2C0 0.89542 0.89542 0 2 0H26C27.1046 0 28 0.89542 28 2C28 3.10456 27.1046 4 26 4H2C0.89542 4 0 3.10456 0 2ZM0 12C0 10.8954 0.89542 10 2 10H14C15.1046 10 16 10.8954 16 12C16 13.1046 15.1046 14 14 14H2C0.89542 14 0 13.1046 0 12Z" fill="#5663EE"/>
            </svg>
          </ng-container>
          <ng-template #expandedIcon>
            <!-- Show the second icon when collapseSidebar is false -->
            <div class="svg-cross" >
              <svg xmlns="http://www.w3.org/2000/svg"  width="4rem" height="4rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18" fill="#5663EE" ></line></svg>
            </div>
          </ng-template>
        </div>
  
        <a routerLink="" class="d-flex d-lg-none d-xl-none d-xxl-none d-md-flex d-sm-flex">
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" /> -->
          <svg width="4.125rem" height="2.688rem" viewBox="0 0 66 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1579_3603)">
              <path d="M23.953 33.7595L25.0239 34.6117C23.4708 37.2302 21.6878 39.215 19.6692 40.5719C17.6507 41.9288 15.4752 42.6016 13.1371 42.6016C9.2515 42.6016 6.08917 41.2055 3.65014 38.4076C1.21111 35.6098 0 31.9764 0 27.5021C0 23.0277 1.21671 19.5626 3.65574 16.6806C6.09477 13.793 9.10011 12.3521 12.6774 12.3521C16.2546 12.3521 18.9403 13.636 21.1102 16.2096C22.1868 17.5049 23.011 19.0131 23.5885 20.7289C24.166 22.4446 24.452 24.3622 24.452 26.476H7.74883C7.74883 28.231 7.94507 29.7897 8.33756 31.141C8.73005 32.4979 9.31317 33.6585 10.0813 34.6285C11.5672 36.4957 13.653 37.4264 16.3443 37.4264C17.1293 37.4264 17.8358 37.3647 18.4581 37.2358C19.0805 37.1068 19.7197 36.8769 20.3813 36.5461C21.6261 35.9406 22.8148 35.0098 23.953 33.7595ZM7.74883 23.9024H12.7839C14.9201 23.9024 16.2266 23.7174 16.7144 23.3417C17.1966 22.966 17.4377 22.1586 17.4377 20.9195C17.4377 18.9122 17.0059 17.3198 16.1369 16.1424C15.2678 14.9649 14.0791 14.3762 12.5652 14.3762C11.0513 14.3762 9.77855 15.1667 8.96554 16.7423C8.15253 18.3235 7.74883 20.7064 7.74883 23.9024Z" fill="#5663EE"/>
              <path d="M25.6856 41.7775V40.7122C26.3416 40.6 26.8014 40.2917 27.0705 39.7814C27.334 39.2712 27.4686 38.0825 27.4686 36.221V11.3317C27.4686 9.3412 27.306 8.02356 26.992 7.37315C26.6724 6.72275 26.0669 6.26298 25.1697 5.98823V4.81077C26.8686 4.46314 28.3937 3.88562 29.7394 3.07261C31.0907 2.2596 32.2569 1.23352 33.2438 -0.00561523H35.0492V36.2154C35.0492 38.0937 35.1726 39.2768 35.4193 39.759C35.666 40.2412 36.137 40.5552 36.8266 40.701V41.7663H25.6856V41.7775Z" fill="#5663EE"/>
              <path d="M65.0801 38.688C63.9307 39.9159 62.7252 40.8803 61.4637 41.5643C60.2021 42.254 59.019 42.596 57.9032 42.596C56.7145 42.596 55.7838 42.2372 55.0997 41.5195C54.4157 40.8018 53.9896 39.6748 53.827 38.1329C51.9991 39.5851 50.3114 40.6897 48.7639 41.4522C47.2107 42.2148 45.8539 42.596 44.682 42.596C42.8373 42.596 41.301 41.9344 40.0675 40.6112C38.8339 39.2879 38.2172 37.6451 38.2172 35.6938C38.2172 34.1239 38.6433 32.6605 39.5012 31.3092C40.359 29.9579 41.5813 28.7805 43.1681 27.7712C44.211 27.0928 45.9548 26.2405 48.4106 25.2088C50.8665 24.1771 52.6663 23.3866 53.8157 22.8371V19.2486C53.8157 17.5105 53.5634 16.2769 53.0644 15.5368C52.5598 14.7967 51.7468 14.4266 50.6142 14.4266C49.7563 14.4266 49.1059 14.6621 48.6573 15.1387C48.2088 15.6153 47.8331 16.5517 47.5191 17.959C47.0818 19.9831 46.5772 21.34 45.9996 22.0241C45.4221 22.7081 44.6259 23.0501 43.6055 23.0501C42.585 23.0501 41.7552 22.7025 41.0599 22.0072C40.3646 21.312 40.017 20.4653 40.017 19.4617C40.017 17.5833 41.0711 15.9237 43.1793 14.4939C45.2875 13.0585 47.8219 12.3464 50.7768 12.3464C54.2979 12.3464 56.8715 13.0193 58.4976 14.3706C60.1236 15.7218 60.9366 17.8637 60.9366 20.8017V34.5444C60.9366 35.8228 61.0487 36.7311 61.2786 37.2694C61.5085 37.8077 61.9122 38.0768 62.4953 38.0768C62.8261 38.0768 63.0953 38.0432 63.3027 37.9647C63.5102 37.8918 63.7457 37.7572 64.0036 37.5554L65.0689 38.6824L65.0801 38.688ZM53.827 25.0238C50.8889 26.3919 48.7695 27.7432 47.4743 29.0776C46.1791 30.4121 45.5286 31.8811 45.5286 33.4847C45.5286 34.707 45.8819 35.7051 46.594 36.4676C46.9584 36.8321 47.3677 37.1068 47.8275 37.2862C48.2817 37.4713 48.8199 37.561 49.4423 37.561C50.2105 37.561 50.973 37.3872 51.73 37.0395C52.4869 36.6919 53.1878 36.1817 53.827 35.5088V25.0238Z" fill="#5663EE"/>
              </g>
              <defs>
              <clipPath id="clip0_1579_3603">
              <rect width="65.0801" height="42.6017" fill="white"/>
              </clipPath>
              </defs>
          </svg>
      
          <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
        </a>
      </div>
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <!-- <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li> -->
           <!-- <app-svg-icon [icon]="'search'"></app-svg-icon> -->
        <!-- <li>
          <span class="header-search d-flex" (click)="searchToggle()">
         
            <svg width="1.375rem" height="1.375rem" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.2939 13.5786H14.3905L14.0703 13.2699C15.191 11.9663 15.8656 10.2739 15.8656 8.43282C15.8656 4.32762 12.538 1 8.43282 1C4.32762 1 1 4.32762 1 8.43282C1 12.538 4.32762 15.8656 8.43282 15.8656C10.2739 15.8656 11.9663 15.191 13.2699 14.0703L13.5786 14.3905V15.2939L19.2962 21L21 19.2962L15.2939 13.5786ZM8.43282 13.5786C5.58548 13.5786 3.28702 11.2802 3.28702 8.43282C3.28702 5.58548 5.58548 3.28702 8.43282 3.28702C11.2802 3.28702 13.5786 5.58548 13.5786 8.43282C13.5786 11.2802 11.2802 13.5786 8.43282 13.5786Z" fill="#1E1A17" stroke="white" stroke-width="0.5"/>
            </svg>
          </span>
        </li> -->

        <!-- <li>
            <app-setting></app-setting>
        </li> -->


        <!-- <li class="onhover-dropdown">
          <app-bookmark></app-bookmark>
        </li> -->
        <!-- <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li> -->
        <!-- <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li> -->
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
