import { BlobOptions } from "buffer";
import { CommonPagination } from "src/app/shared/common-model/common.model";


export enum EventType
{
    InPersonEvent   = 1,
    VirtualEvent    = 2
}

export enum EventStatus
{
    Approved  = 1,
    Rejected  = 2,
    Pending   = 3,
    PartiallyRejected = 4,
    Completed = 5,
    Expired   = 6,
    Suspended = 7 ,// this will be after event is approved and then suspended for any reason 
    Cancelled = 8 , // added for static
    PayPalPending  = 9,
    PayPalRejected = 10
}

// export enum EventListStatus {
//     'Approved'     = 1,
//     'Rejected'     = 2,
//     'Pending'      = 3,
//     'Partial Rej.' = 4,
//     'Completed'    = 5,
//     'Expired'      = 6,
//     'Suspended'    = 7
//   }

export class GetEventsRequestModel extends  CommonPagination {
    categoryIds         : string[]=null;
    eventTypeId         : number;
    cityId              : number = 0;
    dateFilterStartDate : string=null;
    dateFilterEndDate   : string=null;
    statusId            : number=0;
    sortDirection       ="desc"

}

  export class GetEventAttendeesRequestModel extends CommonPagination {
    eventId             : string;
    includeTickets      : boolean = false;
    downloadable        : boolean = false;
  }

 
  export class filterDropdown {
    id                  : string | number ;
    name                : string
 }

 export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
 }
 
  /////////////////////////////////////////////


//event details
  export class EventDetailsResponceModel {
    eventId               : string;
    eventMetadata         : EventMetadata;
    eventDraftId          : any;
    eventDetails          : EventDetails;
    eventTypeId           : number;
    inPersonEventDetail   : InPersonEventDetail;
    virtualEventDetail    : VirtualEventDetail;
    additionalEventDetails: AdditionalEventDetails;
    isRowSelect          ?: boolean;
    ischeck              ?: boolean;
  }
  
  export class EventMetadata {
    eventCreator          : EventCreator;
    coverImage            : CoverImage;
    categoryName          : string;
    eventType             : string;
    purchasableTicketQuantity: number;
    cityName              : string;
    stateName             : string;
    locationFullAddress   : string;
    eventStatus           ?: string;
    eventStatusId         ?: number
    currentEventStatus    ?: string; //for eventlist
    currentEventStatusId  ?: number; //for eventlist
    collaborators         : any;
    attendees             : any;
    isAttending           : boolean;
    isBookmarked          : boolean;
    isHosted              : boolean;
    //event details show more
    totalTicketSales     ?: number;
    financialStatus      ?: string;
    numberOfTicketSold   ?: number;
    numberOfHostedEvents ?: number;
    numberOfReportsAgainstHost?: number;
    eventDisplayId        ?: string;
    eventStatusHistorys   ?:  EventStatusHistory[];
    isPartialRejectionAllowed:boolean;
    eventTimelineStatusId  : number;
    eventTimelineStatus    : string;
  }
  
  export class EventCreator {
    userId                : string;
    name                  : string;
    email                 ?: string; //show more extra details
    profileImage          : ProfileImage;
  }
  
  export class ProfileImage {
    imageUrl              : string;
    thumbnailUrl          : string;
  }
  
  export class CoverImage {
    imageUrl              : string;
    thumbnailUrl          : string;
  }
  
  export class EventDetails {
    eventName             : string;
    isMultiDate           : boolean;
    startTimeUtc          : string;
    endTimeUtc            : string;
    publicEmailAddress    : string;
    categoryId            : string;
    description           : string;
    coverImageFileName    : string;
  }
  
  export class InPersonEventDetail {
    onlyShowFullAddress   : boolean;
    locationName          : string;
    addressLine1          : string;
    addressLine2          : string;
    cityId                : string;
    stateId               : string;
    zipCode               : string;
    cityName              ?: string;
    stateName             ?: string;
  }

  export class VirtualEventDetail {
    applicationName: string
    link: string
  }
  
  export class AdditionalEventDetails {
    showAttendeeList      : boolean;
    freeToAttend          : boolean;
    pricePerTicket        : number;
    minimumAge            : number;
    availableTicketQuantity: number;
    addCollaborators      : boolean;
  }

  export class EventStatusHistory {
    id                    : string;
    eventStatusId         : number;
    eventStatus           : string;
    reasonForRejectionId? : number;
    reasonForRejectionTitle?: string;
    reasonForRejectionDescription?: string;
    additionalReasonForRejection?   : string;
    createdAt ?                : string
  }
  
  //event Status update request model 
  export class UpdateEventStatusRequestModel{
    newEventStatusId             : number;
    reasonForRejectionId         ?: string;
    additionalReasonForRejection ?: string;

    rejectionDescription         ?:string; //for partial rejection popUp
  }
  
  //

  /////////////////////////////////////////////////

  // for event list 
  export class EventListResponceModel {
    eventId             : string
    eventDisplayId      : string
    eventName           : string
    description         : string
    eventCreator        : EventCreator
    coverImage          : CoverImage
    isMultiDate         : boolean
    startTimeUtc        : string
    endTimeUtc          : string
    categoryId          : string
    categoryName        : string
    eventTypeId         : number
    eventType           : string
    inPersonEventDetail?: InPersonEventDetail
    virtualEventDetail? : VirtualEventDetail
    currentEventStatus  : string
    currentEventStatusId: number
    attendees           ?: any
    isRowSelect         ?: boolean;
    isCheck             ?: boolean;
    lastUpdatedAt        : string;
    eventTimelineStatusId: number;
    eventTimelineStatus  : string;
  }
  
  
  //for filter event list dropdown start // 
  export const eventListStatus:filterDropdown[] = [  
      { id: EventStatus.Approved, name: EventStatus[EventStatus.Approved]},
      { id: EventStatus.Rejected, name: EventStatus[EventStatus.Rejected] },
      { id: EventStatus.PartiallyRejected , name: 'Partial Rej.'},
      { id: EventStatus.Pending, name: EventStatus[EventStatus.Pending] },
      { id: EventStatus.Completed, name: EventStatus[EventStatus.Completed]},
      { id: EventStatus.Cancelled, name: EventStatus[EventStatus.Cancelled]},
      // { id: EventStatus.Expired, name: EventStatus[EventStatus.Expired]},
      // { id: EventStatus.Suspended, name: EventStatus[EventStatus.Suspended]},
      { id: EventStatus.PayPalPending, name: 'PayPal Pending'},
      { id: EventStatus.PayPalRejected, name:'PayPal Rejected'},

      // { id: 0, name: 'All'},
  ]
  export const eventListType:filterDropdown[] = [
      { id: EventType.InPersonEvent, name: "In-Person" },
      { id: EventType.VirtualEvent, name: "Virtual" },
      // { id: 0, name: "All" }
  ]

 
  //for filter event list dropdown end // 