import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventStatus } from 'src/app/components/event-list/event-list.model';
import { GetCitiesRequestModel } from 'src/app/components/master-form-settings/master-form-settings.model';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent {
  
  @Input() dropdownLabel   : string = 'Default Label'
  @Input() StausDropDownData : any[]=[];
  @Input() selectedOption  : any;
  @Input() customDropdownClass: string ='';
  @Input() isMutiselect    : boolean = false;
  @Input() showsearchfield : boolean = false;
  @Output() onOptionSelected :EventEmitter<any>  = new EventEmitter<any>();

  selectedList:any[]=[];
  selectedIds:string[]=[];

  selectOption(option) {  
    this.selectedOption = option;
    this.onOptionSelected.emit(option);
  }

  selectOptions(option ,isSelectedFromStoreData=false) {  
    if(JSON.stringify(this.selectedList).includes(JSON.stringify(option))){
      this.selectedList = this.selectedList.filter((item)=>(item !== option));
      this.selectedIds  = this.selectedIds.filter((item)=> (item !== option?.id))
    }else{
      this.selectedList = [...this.selectedList , option];
      this.selectedIds  = [...this.selectedIds , option?.id]
    }

    if(!isSelectedFromStoreData) this.onOptionSelected.emit(this.selectedIds);

  }

  checkIsSelected(option):boolean{
     return ( JSON.stringify(this.selectedList).includes(JSON.stringify(option)))
  }

  onClear(event){
    event?.stopPropagation()
    this.selectOption(undefined)
  }

  multiClearSelectedList(){
    this.selectedList =[];
    this.selectedIds =[];
    this.onOptionSelected.emit(this.selectedIds)
  }

  selectStoreOption(id){
    const option = this.StausDropDownData?.find((x)=> x?.id == id);
    if(option) this.selectedOption = option;
  }

  selectStoreOptions(listOfIds = []){
    listOfIds?.forEach(id => {
      const option = this.StausDropDownData?.find((x)=> x?.id == id);
      if(option) this.selectOptions(option , true);
    });
  }
}
