<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.png" alt=""> -->
  <div class="media-body cursor-pointer"><span class="custom-f-14">{{userDetails?.fullName}}</span>
    <p class="mb-0 font-rubik custom-f-12 text-theme-color-sapphire-50">{{userDetails?.userRole ?? "Admin"}} <i class="fa-solid pt-1 fa fa-caret-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li class="cursor-pointer"><a><app-feather-icons [icon]="'user'"></app-feather-icons><span class="custom-f-14">Account </span></a></li> -->
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li> -->
  <!-- <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <!-- <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li class="cursor-pointer" (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span class="custom-f-14">Log out</span></li>
</ul>