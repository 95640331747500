export class Common {
}



export class CommonPagination {
    sortColumn      : string;
    sortDirection   : string="asc";
    pageNumber      : number = 1;
    pageSize        : number = 15;
    searchText      : string;
    getAll          : boolean = false;
  }

  export class ResponseModel {
    isSuccess       : boolean;
    message         : string;
    response        : any;
    errors          : any;
    status          : number;
    totalRecords    : number;
}

export enum Inputs{
  NormalInput = 1,
  LoginInput  = 2,
  SearchInput = 3,
  FloatingInput = 4
}

export enum Dropdown{
  sapphire50Dropdown = 1,
  darkDropdown = 2
}

export enum DateRangeSelection {
  Today = 1,
  Tomorrow= 2,
  ThisWeekend = 3,
  ThisWeek = 4,
  NextWeek = 5,
  ThisMonth = 6,
  Yesterday= 7,
  Last7days = 8,
  Last30days = 9,
  Next7days = 10,
  Next30days = 11,
  Custom = 12
}


export class SelectedDateFilterOption {
  id :number;
  name : string;
}

export enum DownloadFileOrPrint{
  Pdf   = 1,
  Print = 2,
  Csv   = 3
}