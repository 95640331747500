import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationSidebarComponent } from '../../../notification-sidebar/notification-sidebar.component';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import {NotificationIds, NotificationReadStatusFilter, NotificationReferenceIdType, NotificationRequestModel, NotificationResponseModel} from './notification.model'
import {NotificationService} from './notification.service'
import { utcToLocal } from 'src/app/shared/common';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EventListService } from 'src/app/components/event-list/event-list.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit,OnDestroy {
  @ViewChild("notificationSidebarComponent") notificationSidebarComponent!: NotificationSidebarComponent;
  public openNotification: boolean = false;
  public openClosedSidebarNotification:boolean = false;
  notificationListRequestModel= new NotificationRequestModel();
  notificationResponseModel= new NotificationResponseModel();
  notificationBadgeCount : number = 0;
  notificationIds = new NotificationIds();
  private converter: utcToLocal = new utcToLocal();
  notificationReadStatusFilter = NotificationReadStatusFilter;
  notificationSubscription : Subscription;
  notificationList:NotificationResponseModel[] = [];
  notificationReferenceIdType = NotificationReferenceIdType;

  constructor(private offcanvasService: NgbOffcanvas , public notificationService:NotificationService , public toaster:ToastrService  ,private router : Router ,public eventListService : EventListService ) {
    this.notificationSubscription = this.notificationService.triggerNotification.subscribe((res)=>{
      if(res){
        this.incrementBadgeCount();
        if(this.openClosedSidebarNotification){
          this.notificationListRequestModel = new NotificationRequestModel();
          this.getNotifications(this.notificationListRequestModel.notificationReadStatusFilterId??this.notificationReadStatusFilter.All)
        }
      }
    })
   }

   incrementBadgeCount() {
    this.notificationBadgeCount++;
  }

  onHoverGetNotifications(){
    this.notificationListRequestModel= new NotificationRequestModel();
    this.getNotifications(this.notificationReadStatusFilter.All);
  }

  ngOnInit() {
    this.getNotifications(this.notificationReadStatusFilter.All);
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  openViewAllNotificationDrawer(content: TemplateRef<any>) {
    this.openClosedSidebarNotification=true;
    setTimeout(() => {
		const offcanvasRef: NgbOffcanvasRef = this.offcanvasService.open(this.notificationSidebarComponent.content, { position: 'end' });
    offcanvasRef.dismissed.subscribe(() => {
      this.openClosedSidebarNotification=false;
    });
    }, 100);
	}

  convetUtcTolocalDate(date:string){
    return this.converter.convetUtcTolocalDate(date);
  }

  convetUtcTolocalTime(date:string){
    return this.converter.convetUtcTolocalTime(date);
  }

  getNotifications(notificationReadStatusId = this.notificationReadStatusFilter.All , fromScroll=false){
    this.notificationListRequestModel.notificationReadStatusFilterId = notificationReadStatusId;
    this.notificationService.getAllNotification(this.notificationListRequestModel).subscribe({
      next : (res) =>{
        if(res?.isSuccess){
          if(!fromScroll){
            this.notificationList = res?.response?.list??[];
          } else if(fromScroll){
            this.notificationList = this.notificationList.concat(res?.response?.list);
          }
          this.notificationBadgeCount = res?.response?.notificationBadgeCount;
        }
        else{
          this.notificationList = [];
        }
      },
      error : (err) =>{
        console.log(err);
      }
    })
  }

  ngOnDestroy(): void {
    this.notificationSubscription?.unsubscribe();
  }

  markAllAsRead(){
    this.notificationService.markAllAsReadNotifications().subscribe({
      next:(res)=>{
        if(res?.isSuccess){
          this.notificationListRequestModel= new NotificationRequestModel();
          this.getNotifications(this.notificationListRequestModel.notificationReadStatusFilterId??this.notificationReadStatusFilter.All)
        }

      },error:(err)=>{
        console.log(err)
      }
    })
  }

  markAsRead(notificationItem){
    const notificationId = notificationItem?.id;
    notificationItem.isSeen = true;
    if( notificationItem.referenceIdType  == 'Event'){
      this.navigateToEventList(notificationItem)
    }

    this.notificationService.markAsReadNotifications(notificationId).subscribe({
      next:(res)=>{
        console.log(res)
      },error:(err)=>{
        console.log(err)
      }
    })
  }

  onScroll() {
    if (this.notificationList?.length >=this.notificationListRequestModel.pageNumber * this.notificationListRequestModel.pageSize) {
      const container = document.querySelector(".notifications-auto-scroller");
      if (container) {
        const maxScroll = container.scrollHeight - container.clientHeight;
        const currentScroll = container.scrollTop;
        if (maxScroll <= currentScroll + 15) {
          this.notificationListRequestModel.pageNumber += 1;
          this.getNotifications(this.notificationListRequestModel.notificationReadStatusFilterId??this.notificationReadStatusFilter.All ,true)
        }
      }
    }
  }

  getNotificationOnTabClick(event){
    this.notificationListRequestModel= new NotificationRequestModel();
    this.getNotifications(event)
  }

  navigateToEventList(notificationItem){
    this.router.navigate(['/event-list']);
    this.eventListService.notificationEvent.next(notificationItem);
  }

}