import { Component ,EventEmitter,Input, Output } from '@angular/core';
import {Inputs} from '../../common-model/common.model'
import { ControlContainer, NgForm } from '@angular/forms';
import { NgClass } from '@angular/common';
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class InputComponent {
  // controlContainer: ControlContainer;
  inputs = Inputs;
  @Input() label?:string;
  @Input() idAndName?:string='';
  @Input() placeholder?:string='';
  @Input() type?:string='text';
  @Input() inputClass:string='';
  @Input() inputValue:any;
  @Input() inputComp=this.inputs.NormalInput;
  @Input() isRequired:boolean=false;
  @Input() readOnly:boolean=false;
  @Input()isPassPattern:boolean=false;
  @Input()isEmailPattern:boolean=false;
  @Input('ngClass') ngClass: NgClass["ngClass"];

  public show: boolean = false;
  // public passPattern = "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@#$%^&*_!])[A-Za-z0-9@#$%^&*_!]{8,}.*$"; //with special character
  // public passPattern = "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}.*$"; //without special character
    // public passPattern = "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9@#$%^&*_!]{8,}.*$"; //with special character but not mandatory
  public passPattern = "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9@#$%^&*_!\\(\\)]{8,}$"; ; //with special character include bracket () but not mandatory
  public emailPattern :string = "[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([cC][oO][mM]))(:[0-9]{1,5})?";
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() onEnterEmit?:EventEmitter<string> = new EventEmitter();
  
  showPassword(){
    this.show = !this.show;
    this.show ? this.type='text' : this.type='password';
  }

  onEnter(event){
    if(event.key =='Enter'){
      this.onEnterEmit.emit();
    }
  }
}