import { Component, ElementRef, ViewChild , Input, Output ,EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { NotificationIds, NotificationReadStatusFilter, NotificationResponseModel } from '../header/elements/notification/notification.model';
import { utcToLocal } from '../../common';
import { NotificationService } from '../header/elements/notification/notification.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnChanges {
  @ViewChild('content') content!: ElementRef;
  @Input() notificationsData:NotificationResponseModel[];
  private converter: utcToLocal = new utcToLocal();
  notificationIds = new NotificationIds();
  
  
  notificationReadStatusFilter = NotificationReadStatusFilter;
  openTab:number = NotificationReadStatusFilter.All;
  @Output() clickOnNotificationTab = new EventEmitter();
  @Output() markAsRead    = new EventEmitter();
  @Output() markAllAsRead = new EventEmitter();
  @Output() onScroll = new EventEmitter();

  constructor( public notificationService:NotificationService ,  public toaster:ToastrService){}
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  convetUtcTolocalDate(date:string){
    return this.converter.convetUtcTolocalDate(date);
  }

  convetUtcTolocalTime(date:string){
    return this.converter.convetUtcTolocalTime(date);
  }


 }