import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const storageservice: StorageService = inject(StorageService);
  const router: Router = inject(Router);

  let user = storageservice.retrieve('authorizationDataIdToken');
  if(user) return true;

  router.navigateByUrl('auth/login');
  return false;
};
