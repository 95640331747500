import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  @Input() isVisibleBackLabel:boolean = false;
  @Input() displayonReports:boolean = false;
  @Output() backToPreviousPage = new EventEmitter()

  constructor() {
  }

  ngOnInit() {  }

}
