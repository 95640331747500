import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "event-list",
    loadChildren: () => import("../../components/event-list/event-list.module").then((m) => m.EventListModule),
  },
  // {
  //   path: "event-list/event-details",
  //   loadChildren: () => import("../../components/event-request/event-request.module").then((m) => m.EventRequestModule),
  // },
  {
    path: "app-user-list",
    loadChildren: () => import("../../components/app-user-list/app-user-list.module").then((m) => m.AppUserListModule),
  },
  
  {
    path: "user-management",
    loadChildren: () => import("../../components/user-management/user-management.module").then((m) => m.UserManagementModule),
  },
  {
    path: "reports",
    loadChildren: () => import("../../components/reports/reports.module").then((m) => m.ReportsModule),
  },
  {
    path: "master-form-settings",
    loadChildren: () => import("../../components/master-form-settings/master-form-settings.module").then((m) => m.MasterFormSettingsModule),
  },
];
