import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { authGuard } from "./shared/gaurd/auth.guard";
import { LoginComponent } from "./auth/login/login.component";

const routes: Routes = [

  {
    path: "",
    redirectTo:"auth/login",
    pathMatch:"full",
    
  },

  {
    path: "auth",
    loadChildren: () => import("../app/auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path: "",
    component: ContentComponent,
    children: content,
    canActivate:[authGuard]
  },
  
  {
    path: "",
    component: FullComponent,
    children: full
  },
  {
    path: "**",
    redirectTo: "auth",
  },

  //just sample purpose
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
