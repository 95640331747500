import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { HostedEventsListRequestModel, HostedTicketsListRequestModel, PaymentsListRequestModel, PurchasesMadeListRequestModel, TicketPurchaseListRequestModel, UserListRequestModel } from './app-user-list.model';
import { Observable, tap } from 'rxjs';
import { ResponseModel } from '../../shared/common-model/common.model';

@Injectable({
  providedIn: 'root'
})
export class AppUserListService {

  constructor(private service : DataService) { }

  appliedUserListHostedEventsData :any

  // App User List Api Start
  getUserList(data: UserListRequestModel): Observable<ResponseModel> {
    let url = "identity/users/getAppUsers";
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
  }

  // Hpsted Events Tab Api Start

  getHostedEvents(data: HostedEventsListRequestModel): Observable<ResponseModel> {
    let url = "App/Admin/event/hosted/get";
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
  }

  //User Status Tab Api Start
  getUserStatusHistory(userId:string): Observable<ResponseModel> {
    let url = `identity/Users/userhistory/${userId}`;
    return this.service.get(url,true, '',false ).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
  }
  getStatuswiseUserEventCount(userId:string): Observable<ResponseModel> {
    let url = `App/Admin/${userId}/getStatuswiseUserEventCount`;
    return this.service.get(url,true, '',false ).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
  }

  suspendUser(userId:string): Observable<ResponseModel> {
    let url = `identity/Users/suspend/${userId}`;
    return this.service.putWithId(url, true).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
  }

  unSuspendUser(userId:string): Observable<ResponseModel> {
    let url = `identity/Users/unsuspend/${userId}`;
    return this.service.putWithId(url, true).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
  }

 //User Status Tab Api End


  // Ticket Purchase Api Starts

  getPurchasedTickets(ticketPurchaseListRequestModel:TicketPurchaseListRequestModel):Observable<ResponseModel>{
    let url = `App/Admin/getPurchasedTickets`;
    const json = JSON.stringify(ticketPurchaseListRequestModel);
    console.log(json);
    return this.service.post(url , ticketPurchaseListRequestModel,true).pipe<ResponseModel>(
      tap((response : any)=>{
        return response;
      })
    )
  }


  // Transaction History Tabs Api Starts

  // 1) Hosted Tickets

  getAllHostedTickets(hostedTicketsListRequestModel : HostedTicketsListRequestModel):Observable<ResponseModel>{
    let url = `App/Ticket/getAllHostedTicketsPgnByAdmin`;
    const json = JSON.stringify(hostedTicketsListRequestModel);
    return this.service.post(url ,hostedTicketsListRequestModel , true ).pipe<ResponseModel>(
      tap((response : any)=>{
        return response;
      })
    )
    

  }

  // 2) Payments

  getAllPayments(paymentsListRequestModel : PaymentsListRequestModel):Observable<ResponseModel>{
    let url = `App/Ticket/getAllPaymentsPgnByAdmin`;
    const json = JSON.stringify(paymentsListRequestModel);
    return this.service.post(url , paymentsListRequestModel , true).pipe<ResponseModel>(
      tap((response : any)=>{
        return response;
      })
    )
  }
  
  // 3) Purchases Made

  getAllPurchasesMade(purchasesMadeListRequestModel : PurchasesMadeListRequestModel):Observable<ResponseModel>{
    let url = `app/Ticket/GetAllPurchasesMadePgnByAdmin`;
    const json  = JSON.stringify(purchasesMadeListRequestModel);
    return this.service.post(url , purchasesMadeListRequestModel , true).pipe<ResponseModel>(
      tap ((response : any)=>{
        return response;
      })
    )
  }
}
