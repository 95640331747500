<ng-template #content let-offcanvas>
    <div class="offcanvas-header mb-4 d-flex justify-content-center ">
      <h4 class="offcanvas-title font-inter-700 text-theme-color-dark">Notifications</h4>
      <!-- <button type="button" class="btn-close mt-0" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button> -->
      <svg (click)="offcanvas.dismiss('Cross click')" class="d-lg-none d-block " xmlns="http://www.w3.org/2000/svg" width="14" height="27" viewBox="0 0 14 27">
        <text id="Screen_Title" data-name="Screen Title" transform="translate(0 22)" fill="#e0532f" font-size="20" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.05em"><tspan x="0" y="0">X</tspan></text>
      </svg>
    </div>
    <ul class="nav nav-tabs border-tab nav-primary mb-0 pt-1 px-3" id="info-tab" role="tablist">
        <li class="nav-item px-1">
          <a href="javascript:void(0)" class="nav-link font-inter-700  text-nowrap pt-0 px-1 f-12"  [ngClass]="{'active show': openTab == notificationReadStatusFilter.All}"
          (click)="openTab = notificationReadStatusFilter.All;clickOnNotificationTab.emit(notificationReadStatusFilter.All)"
            id="all">All</a>
        </li>
        <li class="nav-item px-2">
            <a href="javascript:void(0)" class="nav-link font-inter-700  text-nowrap pt-0 px-1 f-12" [ngClass]="{'active show': openTab == notificationReadStatusFilter.Read}"
            (click)="openTab = notificationReadStatusFilter.Read;clickOnNotificationTab.emit(notificationReadStatusFilter.Read)"  
            id="read">Read</a>
        </li>
        <li class="nav-item px-1">
          <a href="javascript:void(0)" class="nav-link font-inter-700  text-nowrap pt-0 px-1 f-12" [ngClass]="{'active show': openTab == notificationReadStatusFilter.Unread}"
          (click)="openTab = notificationReadStatusFilter.Unread;clickOnNotificationTab.emit(notificationReadStatusFilter.Unread)"  
          id="unread">Unread</a>
        </li>      
        <li class="w-100 text-end pe-2">
            <!-- <span class="cursor-pointer" >CLEARALL' | translate</span> -->
            <span class="cursor-pointer" (click)="markAllAsRead.emit()">Mark all as Read</span>
        </li>
    </ul>

    <div class="offcanvas-body  px-4 position-relative d-flex flex-column" >
        <!-- <button type="button" class="btn btn-outline-dark" (click)="offcanvas.close('Close click')">Close</button> -->
        <div class="tab-content flex-fill" id="info-tabContent">
            <div class="tab-pane fade h-100 text-danger" [ngClass]="{'active show': openTab == notificationReadStatusFilter?.All}" id="all" role="tabpanel"
              aria-labelledby="all">
              <div class="notification-dropdown h-100 d-flex flex-column">
                <ul class="d-flex flex-column gap-3 notifications-auto-scroller h-0 flex-fill overflow-auto" (scroll)="onScroll?.emit()">
                    <li class="notification-body cursor-pointer" [ngClass]="{'is-seen pe-none':item?.isSeen}" *ngFor="let item of notificationsData" (click)="item?.isSeen ? null: markAsRead.emit(item)">
                        <p class="notification-body-title d-flex m-0">
                          <span class="col font-inter-700 text-truncate">{{item?.title}} </span>
                          <span class="col font-inter-700 text-end cursor-pointer">{{convetUtcTolocalDate(item?.createdAt)}}</span>
                        </p>
                        <p class="notification-body-subtitle d-flex m-0">
                          <span class="col text-start font-inter-700 text-truncate">{{item?.subTitle}}</span>
                          <span class="col font-inter-700 text-end cursor-pointer">{{convetUtcTolocalTime(item?.createdAt)}}</span>
                        </p>
                        <p class="col notification-Desc font-inter-400">{{item?.description}}</p>
                    </li>
                </ul>
            </div>
            </div>
            <div class="tab-pane fade text-danger h-100" [ngClass]="{'active show': openTab == notificationReadStatusFilter?.Unread}" id="unread" role="tabpanel" aria-labelledby="unread">
              <div class="notification-dropdown h-100 d-flex flex-column">
                <ul class="d-flex flex-column gap-3 notifications-auto-scroller h-0 flex-fill overflow-auto" (scroll)="onScroll?.emit()">
                  <ng-container *ngFor="let item of notificationsData">
                    <li class="notification-body cursor-pointer"  (click)="item?.isSeen ? null: markAsRead.emit(item)" *ngIf="!item?.isSeen">
                        <p class="notification-body-title d-flex m-0">
                          <span class="col font-inter-700 text-truncate">{{item.title}}</span>
                          <span class="col font-inter-700 text-end cursor-pointer">{{convetUtcTolocalDate(item.createdAt)}}</span>
                        </p>
                        <p class="notification-body-subtitle d-flex m-0">
                          <span class="col text-start font-inter-700 text-truncate">{{item.subTitle}}</span>
                          <span class="col font-inter-700 text-end cursor-pointer">{{convetUtcTolocalTime(item?.createdAt)}}</span>
                        </p>
                        <p class="col notification-Desc font-inter-400">{{item.description}}</p>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="tab-pane fade h-100 text-danger" [ngClass]="{'active show': openTab == notificationReadStatusFilter?.Read}" id="read" role="tabpanel" aria-labelledby="read">
              <div class="notification-dropdown h-100 d-flex flex-column">
                <ul class="d-flex flex-column gap-3 notifications-auto-scroller h-0 flex-fill overflow-auto" (scroll)="onScroll?.emit()">
                    <li class="notification-body" [ngClass]="{'is-seen pe-none':item?.isSeen}" *ngFor="let item of notificationsData">
                        <p class="notification-body-title d-flex m-0">
                          <span class="col font-inter-700 text-truncate">{{item.title}}</span>
                          <span class="col font-inter-700 text-end cursor-pointer">{{convetUtcTolocalDate(item.createdAt)}}</span>
                        </p>
                        <p class="notification-body-subtitle d-flex m-0">
                          <span class="col text-start font-inter-700 text-truncate">{{item.subTitle}}</span>
                          <span class="col font-inter-700 text-end cursor-pointer">{{convetUtcTolocalTime(item?.createdAt)}}</span>
                        </p>
                        <p class="col notification-Desc font-inter-400">{{item.description}}</p>
                    </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="position-absolute top-50 start-50 translate-middle color-primary-grey" *ngIf="notificationsData?.length ==0">
            No notifications to show!
        </div>
    </div>

</ng-template>