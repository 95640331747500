<div class="container p-0" [ngClass]="{'custom-dash' :isDashed ,'disabledWhenHardcode' :isHardCoded}">
    <input 
      [ngClass]="{'action-disabled':readonly}"
      [disabled]="readonly"
      [(ngModel)]="status" 
      id="{{ dyanamicId ? dyanamicId : lableName }}"
      (click)="check.emit($event.target.checked)"
      (change)="changed.emit($event.target.checked)"
      type="checkbox">
    <div class="checkmark d-flex align-items-center justify-content-center {{chekMarkCss}}">
      <span *ngIf="isDashed" class="dash-color"></span>
    </div>
    <label *ngIf="lableName?.length >0 "
      class="form-check-label my-auto ms-2"
      [ngClass]="{'text-primary-blue':status,'action-disabled':readonly}"
      for="{{ dyanamicId ? dyanamicId : lableName }}">
      {{ lableName | translate}}
    </label>
  </div>