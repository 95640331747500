import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "src/app/auth/auth.model";
import { StorageService } from "src/app/shared/services/storage.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  userDetails = new User();
  constructor(public router: Router ,private storage:StorageService) {
    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  ngOnInit() {
    this.userDetails = this.storage.retrieve('userData')
  }

  logoutFunc() {
    this.router.navigateByUrl('auth/login');
    // this.storage.clear();
    this.storage.removeItem("authorizationDataIdToken");
    this.storage.removeItem("userData");
  }
}
