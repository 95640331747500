<div *ngIf="inputComp == inputs.NormalInput" class="form-group common_input w-100">
    <label *ngIf="label" for="exampleInputEmail1">{{label}}</label>
    <input 
        autocomplete="off"
        [type]="type" 
        [class]="inputClass + ' form-control'"
        [id]="idAndName" 
        [ngClass]="ngClass" 
        [name]="idAndName"
        [placeholder]="placeholder"
        [(ngModel)]="inputValue" 
        (ngModelChange)="inputValueEmit.emit(inputValue)" 
        [required]="isRequired" 
        [email]="true"
        [readOnly]="readOnly" 
        [email]="type =='email'?true:false"
        (keydown)="onEnter($event)"
        >
  </div>

  <div *ngIf="inputComp == inputs.LoginInput" class="form-group login_input">
    <input 
        autocomplete="off" 
        [type]="type" 
        [class]="inputClass + ' form-control'"
        [id]="idAndName" 
        [name]="idAndName"
        [placeholder]="' '"
        [(ngModel)]="inputValue"
        [readOnly]="readOnly"
        [required]="isRequired" 
        [email]="type =='email'?true:false" 
        [pattern]="isPassPattern?passPattern :null || isEmailPattern?emailPattern : null"
        (ngModelChange)="inputValueEmit.emit(inputValue)">
    <label [for]="idAndName" *ngIf="label" class="col-form-label">{{label}} <span><svg class="mb-1" width="6" height="6" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5568 6.9091L2.66475 4.51137L0.642023 5.81251L0.0227051 4.73297L2.17043 3.63637L0.0227051 2.53978L0.642023 1.46024L2.66475 2.76137L2.5568 0.363647H3.78975L3.6818 2.76137L5.70452 1.46024L6.32384 2.53978L4.17611 3.63637L6.32384 4.73297L5.70452 5.81251L3.6818 4.51137L3.78975 6.9091H2.5568Z" fill="#F35237"/></svg></span></label>
    <div [ngClass]="{'invisible':!label?.toLowerCase().includes('password')}" class="show-hide" (click)="showPassword()" *ngIf="!show">
      <svg class="cursor-pointer" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8729 9.61096C19.6942 9.36655 15.4371 3.62634 9.9999 3.62634C4.56274 3.62634 0.305391 9.36655 0.126914 9.61073C-0.0423048 9.84261 -0.0423048 10.1571 0.126914 10.389C0.305391 10.6334 4.56274 16.3736 9.9999 16.3736C15.4371 16.3736 19.6942 10.6333 19.8729 10.3892C20.0423 10.1573 20.0423 9.84261 19.8729 9.61096ZM9.9999 15.0549C5.99486 15.0549 2.52606 11.245 1.49922 9.99952C2.52473 8.75292 5.98626 4.94502 9.9999 4.94502C14.0048 4.94502 17.4733 8.75424 18.5006 10.0004C17.4751 11.247 14.0135 15.0549 9.9999 15.0549Z" fill="#A6978E"/>
        <path d="M9.99992 6.04395C7.81858 6.04395 6.04385 7.81868 6.04385 10C6.04385 12.1813 7.81858 13.9561 9.99992 13.9561C12.1812 13.9561 13.956 12.1813 13.956 10C13.956 7.81868 12.1812 6.04395 9.99992 6.04395ZM9.99992 12.6374C8.54562 12.6374 7.36257 11.4543 7.36257 10C7.36257 8.54575 8.54566 7.36266 9.99992 7.36266C11.4542 7.36266 12.6373 8.54575 12.6373 10C12.6373 11.4543 11.4542 12.6374 9.99992 12.6374Z" fill="#A6978E"/>
    </svg>
    <!-- <span class="show"></span> -->
  </div>
    <div [ngClass]="{'invisible':!label?.toLowerCase().includes('password')}" class="show-hide" (click)="showPassword()" *ngIf="show">
      <svg class="cursor-pointer" width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_481_2113)">
        <g clip-path="url(#clip1_481_2113)">
        <path d="M12.483 10.1685L13.6189 9.03254C13.7016 9.34176 13.75 9.66504 13.75 10C13.75 12.0679 12.0678 13.75 10 13.75C9.665 13.75 9.34172 13.7016 9.0325 13.6189L10.1684 12.483C11.4105 12.3983 12.3983 11.4105 12.483 10.1685ZM19.9212 9.69644C19.8444 9.55816 18.7541 7.6477 16.6228 6.02863L15.7133 6.93816C17.2673 8.08082 18.252 9.41348 18.6397 10.001C17.8989 11.1289 14.993 15 10 15C9.25125 15 8.555 14.9043 7.89984 14.7514L6.87281 15.7785C7.81703 16.068 8.85484 16.25 10 16.25C16.5427 16.25 19.7864 10.5463 19.9212 10.3036C20.0263 10.1147 20.0263 9.88535 19.9212 9.69644ZM17.3169 3.56691L3.56687 17.3169C3.44484 17.4389 3.28484 17.5 3.125 17.5C2.96516 17.5 2.80516 17.4389 2.68313 17.3169C2.43891 17.0727 2.43891 16.6772 2.68313 16.4332L4.4325 14.6838C1.60437 12.9764 0.167344 10.4632 0.07875 10.3036C-0.02625 10.1147 -0.02625 9.88519 0.07875 9.69629C0.213594 9.45379 3.45734 3.75004 10 3.75004C11.6917 3.75004 13.157 4.13598 14.4097 4.7066L16.4331 2.68316C16.6773 2.43895 17.0728 2.43895 17.3169 2.68316C17.5609 2.92738 17.5611 3.32285 17.3169 3.56691ZM5.36453 13.7518L6.94687 12.1694C6.50984 11.5563 6.25 10.8086 6.25 10C6.25 7.93223 7.93219 6.25004 10 6.25004C10.8086 6.25004 11.5563 6.50988 12.1694 6.94676L13.4489 5.66723C12.438 5.26238 11.2892 5.00004 10 5.00004C5.00703 5.00004 2.10109 8.87113 1.36047 9.9991C1.83078 10.7119 3.18266 12.5163 5.36453 13.7518ZM7.85422 11.2619L11.2619 7.85426C10.8902 7.63473 10.4622 7.50004 10 7.50004C8.62156 7.50004 7.5 8.6216 7.5 10C7.5 10.4622 7.63469 10.8902 7.85422 11.2619Z" fill="#A6978E"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_481_2113">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        <clipPath id="clip1_481_2113">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
      </svg>
  </div>
  </div>
  <!-- <span class="hide"></span> -->


  <div  *ngIf="inputComp == inputs.FloatingInput" class="form-floating mb-3 mt-3">
    <input  
        autocomplete="off" 
        [type]="type" 
        [class]="inputClass + 'form-control'"
        [id]="idAndName" 
        [name]="idAndName"
        [ngClass]="ngClass" 
        [placeholder]="' '"
        [(ngModel)]="inputValue"
        [readOnly]="readOnly"
        [required]="isRequired" 
        [email]="type =='email'?true:false" 
        [pattern]="isPassPattern?passPattern :null || isEmailPattern?emailPattern : null"
        (ngModelChange)="inputValueEmit.emit(inputValue)">
    <label [for]="idAndName" class="custom-label mb-0">{{label}} <span><svg class="mb-1" width="6" height="6" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5568 6.9091L2.66475 4.51137L0.642023 5.81251L0.0227051 4.73297L2.17043 3.63637L0.0227051 2.53978L0.642023 1.46024L2.66475 2.76137L2.5568 0.363647H3.78975L3.6818 2.76137L5.70452 1.46024L6.32384 2.53978L4.17611 3.63637L6.32384 4.73297L5.70452 5.81251L3.6818 4.51137L3.78975 6.9091H2.5568Z" fill="#F35237"/></svg></span></label>
  </div>