import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() id?:string;
  @Input() name?:string;
  @Input() buttonType:string;
  @Input() buttonClass:string;
  @Input() disabled:boolean = false;
  @Input() button_with_icon = false
  @Input() btn_with_label_icon = false;
  @Input() icon_before = false;
  @Input() arrowUp = false;
  @Input() arrowDown = false;
  @Output("buttonOutputEvent") buttonOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() buttonLabel:string;
  @Input() button_with_label = true;
  @Input() isVisibleLoader = false;


}
