import { Injectable } from '@angular/core';
import { NotificationIds, NotificationRequestModel } from './notification.model';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ResponseModel } from '../../../../common-model/common.model';
import { DataService } from '../../../../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private service:DataService) { }

  public triggerNotification = new BehaviorSubject<any>(undefined);

  getAllNotification(data : NotificationRequestModel):Observable<ResponseModel>{
    let url = `App/Notification/getall`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response : any) =>{
        return response;
      })
    )
  }

  markAsReadNotifications(id: NotificationIds): Observable<ResponseModel>{
    var url = `App/Notification/updateAsSeen/${id}`;
    return this.service.putWithId(url, id, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  markAllAsReadNotifications(): Observable<ResponseModel>{
    var url = `App/Notification/updateAllAsSeen`;
    return this.service.putWithId(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}

