 
import { DatePipe } from '@angular/common';
import { DateRangeSelection} from './common-model/common.model';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ApexResponsive, ApexNonAxisChartSeries, ApexTitleSubtitle, ApexTheme, ApexMarkers, ApexAnnotations, ApexGrid } from "ng-apexcharts";



export class utcToLocal {
    convetUtcTolocalDate(date:string){
        if(date){
          if(date.includes('Z')){
            var localDate = new Date(date);
          }else{
            var localDate = new Date(date + 'Z');
          }
           // Append 'Z' to indicate UTC time
          const datePipe = new DatePipe('en-US');
          return datePipe.transform(localDate, 'MM/dd/yyyy', 'en-US');
        }
        return ''
      };

      convetUtcTolocalTime(date:string){
        if(date){
        const localDate = new Date(date + 'Z'); // Append 'Z' to indicate UTC time;
        const datePipe = new DatePipe('en-US');
        return (datePipe.transform(localDate, 'h:mm a', 'en-US'))?.toLowerCase();
        }
        return ''
      }

      convetUtcTolocalDateTime(date:string){
        if(date){
        const localDate = new Date(date + 'Z'); // Append 'Z' to indicate UTC time;
        const datePipe = new DatePipe('en-US');
        return (datePipe.transform(localDate, 'MM/dd/yyyy h:mm a', 'en-US'))?.toLowerCase();
        }
        return ''
      }
}

export const DateRangeList = [
 {
  id: DateRangeSelection.Today , name:'Today'
 },
 {
  id:DateRangeSelection.Tomorrow , name:'Tomorrow'
 },
 {
  id:DateRangeSelection.ThisWeekend , name:'This weekend'
 },
 {
  id:DateRangeSelection.ThisWeek , name:'This week'
 },
 {
  id:DateRangeSelection.NextWeek , name:'Next week'
 },
 {
  id:DateRangeSelection.ThisMonth, name:'This month'
 },
 {
  id:DateRangeSelection.Custom , name:'Custom'
 }
];

export const DateRangeSelectionForEventCount = [
  {
    id:DateRangeSelection.Today , name:'Today'
   },
   {
    id:DateRangeSelection.Yesterday , name:'Yesterday'
   },
   {
    id:DateRangeSelection.Last7days , name:'Last 7 days'
   },
   {
    id:DateRangeSelection.Last30days , name:'Last 30 days'
   },
   {
    id:DateRangeSelection.Next7days , name:'Next 7 days'
   },
   {
    id:DateRangeSelection.Next30days, name:'Next 30 days'
   },
   {
    id:DateRangeSelection.Custom , name:'Custom'
   }
]

export const DateRangeListForTicketPurchase = [
  {
    id: DateRangeSelection.Today , name:'Today'
   },
   {
    id:DateRangeSelection.Yesterday , name:'Yesterday'
   },
   {
    id:DateRangeSelection.Last7days , name:'Last 7 days'
   },
   {
    id:DateRangeSelection.Last30days , name:'Last 30 days'
   },
   {
    id:DateRangeSelection.Custom , name:'Custom'
   }
 
]

export const ngbModalCommonOptions:NgbModalOptions = {
  backdrop    :'static',
  centered    :true,
  size        :'sm',
  windowClass :'confirm_popUp'
}

export type ChartOptions = {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  stroke?: ApexStroke;
  tooltip?: any;
  dataLabels?: ApexDataLabels;
  yaxis?: ApexYAxis;
  legend?: ApexLegend;
  labels?: string[];
  plotOptions?: ApexPlotOptions;
  fill?: ApexFill;
  responsive?: ApexResponsive[];
  pieseries?: ApexNonAxisChartSeries;
  title?: ApexTitleSubtitle;
  theme?: ApexTheme;
  colors?: string[];
  markers?: ApexMarkers;
  annotations?: ApexAnnotations;
  grid?: ApexGrid;
};


export const EventListStoreData = 'EventListStoreData';
export const HostedEventListStoreData = 'HostedEventListStoreData';
export const StoredCityList = 'StoredCityList';