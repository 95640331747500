<!-- Event Confirmation Popup-->


    <div class="modal-body p-5" *ngIf="modalTitle==eventConfirmPopUP.APPROVED || modalTitle==eventConfirmPopUP.REJECT || modalTitle == eventConfirmPopUP.PARTIALLY_REJECT">
        <div class="mb-3">
            <h5 class="custom-f-24 font-inter-700 text-theme-color-dark text-center lh-1 mb-3">{{modalTitle==eventConfirmPopUP.APPROVED?eventConfirmPopUP.APPROVED:modalTitle==eventConfirmPopUP.REJECT?eventConfirmPopUP.REJECT:eventConfirmPopUP.PARTIALLY_REJECT}} Event</h5>
            <p class="text-theme-color-dark font-inter-400 custom-f-16 text-center pb-1 mb-4">Are you sure you want to <span class="text-lowercase">{{modalTitle==eventConfirmPopUP.APPROVED?eventConfirmPopUP.APPROVED:modalTitle==eventConfirmPopUP.REJECT?eventConfirmPopUP.REJECT:eventConfirmPopUP.PARTIALLY_REJECT}}</span> this event?</p>
        </div>
        <div class="d-flex gap-5 justify-content-center">
            <app-button [buttonClass]="'sapphire-50'" [buttonLabel]="'Yes'" (click)="activeModal.close(true)"></app-button>
            <app-button [buttonClass]="'text-theme-color-sapphire-50 bg-theme-color-white border'" (click)="activeModal.close()" [buttonLabel]="'Cancel'"></app-button>
        </div>
    </div>




<!-- User Status Confirmation Popup -->

    <div class="modal-body p-5" *ngIf="modalTitle === userStatusconfirmPopUP.SUSPEND_USER || modalTitle ==userStatusconfirmPopUP.UN_SUSPEND_USER">
        <div class="mb-3">
            <h5 class="custom-f-24 font-inter-700 text-theme-color-dark text-center lh-1 mb-3"> {{modalTitle === userStatusconfirmPopUP.SUSPEND_USER ? userStatusconfirmPopUP.SUSPEND_USER :userStatusconfirmPopUP.UN_SUSPEND_USER}} User</h5>
            <p class="text-theme-color-dark font-inter-400 custom-f-16 text-center pb-1 mb-4">Are you sure you want to {{modalTitle === userStatusconfirmPopUP.SUSPEND_USER ? 'suspend' :'un-suspend '}} this user? <span *ngIf="modalTitle === userStatusconfirmPopUP.SUSPEND_USER"> They will be unable to purchase tickets or host events.</span></p>
        </div>
        <div class="d-flex gap-5 justify-content-center">
            <app-button [buttonClass]="'sapphire-50'" [buttonLabel]="'Yes'" (click)="activeModal.close(true)"></app-button>
            <app-button [buttonClass]="'text-theme-color-sapphire-50 bg-theme-color-white border'" (click)="activeModal.close()" [buttonLabel]="'Cancel'"></app-button>
        </div>
    </div>

    <div class="modal-body p-5" *ngIf="modalTitle === userStatusconfirmPopUP.SUSPEND_HOST">
        <div class="mb-4 pb-2">
            <h5 class="custom-f-24 font-inter-700 text-theme-color-dark text-center lh-1 mb-3"> {{modalTitle}}</h5>
            <div class="d-flex justify-content-center align-items-center">
                <div>
                    <p class="text-theme-color-dark font-inter-400 custom-f-16 pb-1 mb-1"> This host has the following events:</p>
                    <ul class="text-theme-color-dark font-inter-400 custom-f-16 pb-1 mb-1 ms-1 event_count "> 
                        <li class="font-inter-600"><span class="custom-f-20">&#8226;</span> Approved: {{ eventCountStatusRes?.approvedEventCount ?? 0}} <span></span> </li>
                        <li class="font-inter-600"><span class="custom-f-20">&#8226;</span> Pending: {{  (eventCountStatusRes?.pendingEventCount??0) + (eventCountStatusRes?.payPalPendingCount??0)}} <span></span> </li>
                    </ul>
                </div>
            </div>
            
            
            <p class="text-theme-color-dark font-inter-400 custom-f-16 text-center pb-1 mb-0"> Are you sure you want to suspend this host?</p>
            <p class="text-theme-color-dark font-inter-400 custom-f-16 text-center pb-1 mb-0 text-italic lh-sm "> <em>
                They will be unable to log in, purchase and download tickets, or manage their events.
                Suspending this host will <span class="font-inter-700">NOT</span> cancel their <br>
                active events.
            </em> </p>
        </div>
        <div class="d-flex gap-5 justify-content-center">
            <app-button [buttonClass]="'sapphire-50'" [buttonLabel]="'Suspend'" (click)="activeModal.close(true)"></app-button>
            <app-button [buttonClass]="'text-theme-color-sapphire-50 bg-theme-color-white border'" (click)="activeModal.close()" [buttonLabel]="'Cancel'"></app-button>
        </div>
    </div>



<!-- Active Inactive Confirmation Popup -->

<div class="modal-body p-5" *ngIf="modalTitle === activeConfirmPopup.ACTIVE || modalTitle === activeConfirmPopup.INACTIVE">
    <div class="mb-3">
        <h5 class="custom-f-24 font-inter-700 text-theme-color-dark text-center lh-1 mb-3">Mark as {{modalTitle === activeConfirmPopup.ACTIVE ? activeConfirmPopup.ACTIVE :activeConfirmPopup.INACTIVE}}</h5>
        <p class="text-theme-color-dark font-inter-400 custom-f-16 text-center pb-1 mb-4">Are you sure you want to mark this user {{modalTitle === activeConfirmPopup.ACTIVE ? activeConfirmPopup.ACTIVE :activeConfirmPopup.INACTIVE}}?</p>
    </div>
    <div class="d-flex gap-5 justify-content-center">
        <app-button [buttonClass]="'sapphire-50'" [buttonLabel]="'Yes'" (click)="activeModal.close(true)"></app-button>
        <app-button [buttonClass]="'text-theme-color-sapphire-50 bg-theme-color-white border'" (click)="activeModal.close()" [buttonLabel]="'Cancel'"></app-button>
    </div>
</div>

   