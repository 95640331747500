import { CommonPagination } from "src/app/shared/common-model/common.model";

export class MasterFormSettings {
    masterEventCategory: MasterEventCategory;
    masterState: MasterState;
    masterCity: MasterCity;
    masterReasonForRejection: MasterReasonForRejection
  }
  
  export class MasterEventCategory {
    id: string
    name: string
    description: string
    isActive: boolean
    masterValues: MasterValue[];
    isAdd?:boolean = false;
  }
  
  export class MasterValue {
    id: string 
    masterTableId: string
    masterTableName: string
    name: string=''
    description: any=''
    isActive: boolean=false;
    isEdit:boolean = false;
    imageUrl:string;
    file:File;
    isSvgMaxSize;
    isSvgFormat
  }
  
  export class MasterState {
    id: string
    name: string
    description: string
    isActive: boolean
    masterValues: MasterValue2[]
  }
  
  export class MasterValue2 {
    id: string
    masterTableId: string
    masterTableName: string
    name: string
    description: string
    isActive: boolean
  }
  
  export class MasterCity {
    id: string
    name: string
    description: string
    isActive: boolean
    masterValues: MasterValue3[]
  }
  
  export class MasterValue3 {
    id: string
    masterTableId: string
    masterTableName: string
    name: string
    description: string
    isActive: boolean
  }

  export class MasterReasonForRejection {
    id: string
    name: string
    description: string
    isActive: boolean
    masterValues: MasterValue4[]
  }
  
  export class MasterValue4 {
    id: string
    masterTableId: string
    masterTableName: string
    name: string
    description: string
    isActive: boolean
  }

  //
  export class InsertLookUpValue {
    lookupTableId: string
    newLookupValues: InsertEditLookupValue[]
  }
  
  export class InsertEditLookupValue {
    name: string;
    description: string;
    isActive: boolean;
    imageUrl: string
  }

  export class GetCitiesRequestModel extends CommonPagination {
    getAll          : boolean = true;
  }

  export class CitiesResponceModel {
    cityId    : number
    cityName  : string
    stateId   : number
    stateName : string
    stateCode : string
  }
  
  