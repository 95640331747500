<button *ngIf="button_with_label" class="d-flex align-items-center justify-content-center btn text-nowrap"
    id="{{id}}"
    name="{{name}}"
    [class]="buttonClass"
    [type]="buttonType"
    [ngClass]="ngClass" 
    [disabled]="disabled"
    (click)="buttonOutputEvent.emit()">
   {{buttonLabel}}
</button>

<ng-container *ngIf="icon_before">
    <button  class="d-flex align-items-center justify-content-center btn text-nowrap"
    id="{{id}}"
    name="{{name}}"
    [class]="buttonClass"
    [type]="buttonType"
    [ngClass]="ngClass" 
    [disabled]="disabled"
    (click)="buttonOutputEvent.emit()">
    <ng-container *ngIf="arrowDown">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5.11616 5.59317C5.60227 6.13561 6.39773 6.13561 6.88384 5.59317L11 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>           
    </ng-container>
    <ng-container *ngIf="arrowUp">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 6L6.88384 1.40683C6.39773 0.864389 5.60227 0.864389 5.11616 1.40683L1 6" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
    <span class="px-3">{{buttonLabel}}</span>
    <ng-container  *ngIf="arrowDown">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5.11616 5.59317C5.60227 6.13561 6.39773 6.13561 6.88384 5.59317L11 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>           
    </ng-container>
    <ng-container *ngIf="arrowUp">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 6L6.88384 1.40683C6.39773 0.864389 5.60227 0.864389 5.11616 1.40683L1 6" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>
</button>
</ng-container>


<!-- button wuth icon -->
<div [ngClass]="{'pe-none':isVisibleLoader}" (click)="buttonOutputEvent?.emit()" *ngIf="button_with_icon" class="download_btn cursor-pointer btn-accent">
    <svg *ngIf="!isVisibleLoader" width="0.938rem" height="0.938rem" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3815_9857)">
      <path d="M15 14.0625C15 14.5803 14.5803 15 14.0625 15H0.9375C0.419736 15 0 14.5803 0 14.0625C0 13.5447 0.419736 13.125 0.9375 13.125H14.0625C14.5803 13.125 15 13.5447 15 14.0625ZM6.8371 11.0926C7.02018 11.2757 7.26006 11.3672 7.5 11.3672C7.73988 11.3672 7.97988 11.2757 8.1629 11.0926L11.4845 7.77103C11.8506 7.4049 11.8506 6.81132 11.4845 6.4452C11.1184 6.07907 10.5248 6.07907 10.1587 6.4452L8.4375 8.16639V0.9375C8.4375 0.419736 8.01776 0 7.5 0C6.98224 0 6.5625 0.419736 6.5625 0.9375V8.16639L4.84131 6.4452C4.47519 6.07907 3.8816 6.07907 3.51548 6.4452C3.14936 6.81132 3.14936 7.4049 3.51548 7.77103L6.8371 11.0926Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_3815_9857">
      <rect width="15" height="15" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    <div *ngIf="isVisibleLoader" class="loader-box">
        <div class="loader-19 download_loader"></div>
    </div>
      
</div>


