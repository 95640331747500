<div class="w-100" *ngIf="dropdownComp == dropdown.darkDropdown">
    <div ngbDropdown class="d-inline-block w-100 select_dropdown" >
        <button type="button" class="btn border w-100 py-1 h-100 position-relative" id="dropdownBasic1" ngbDropdownToggle>
            <input [name]="name" [required]="isRequired" [id]="name" type="text" class="border-0 w-100 pe-3 selected_value_input text-truncate custom-f-16" [(ngModel)]="displayName" [placeholder]="' '" readonly>
            <label [for]="dropdownBasic1" *ngIf="label" class="select_form-label position-absolute custom-f-16 top-0 ps-2 start-0 mb-0">{{label}} <span><svg class="mb-1" width="6" height="6" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5568 6.9091L2.66475 4.51137L0.642023 5.81251L0.0227051 4.73297L2.17043 3.63637L0.0227051 2.53978L0.642023 1.46024L2.66475 2.76137L2.5568 0.363647H3.78975L3.6818 2.76137L5.70452 1.46024L6.32384 2.53978L4.17611 3.63637L6.32384 4.73297L5.70452 5.81251L3.6818 4.51137L3.78975 6.9091H2.5568Z" fill="#F35237"/></svg></span></label>

        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="text-truncate border">
            <button class="text-truncate" ngbDropdownItem *ngFor="let item of list" (click)="selectOption(item)">{{item?.name}}</button>
        </div>
    </div>
</div>


<div class="w-100 select_custom_dropdown" *ngIf="dropdownComp == dropdown.sapphire50Dropdown">
    <div ngbDropdown class="d-inline-block w-100 " #dropdown = ngbDropdown >
        <button type="button" class="btn border w-100 py-1 h-100 position-relative" id="dropdownBasic1" ngbDropdownToggle [ngClass]="{'is-open':dropdown?.isOpen()}">
            <input [name]="name" [required]="isRequired" [id]="name" type="text" class="border-0 w-100 pe-3 selected_value_input text-truncate custom-f-16" [(ngModel)]="displayName" [placeholder]="' '" readonly>
            <label [for]="dropdownBasic1" *ngIf="label" class="select_form-label position-absolute custom-f-16 top-0  start-0 mb-0">{{label}}</label>

        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="text-truncate border">
            <button class="text-truncate" ngbDropdownItem *ngFor="let item of list" (click)="selectOption(item)">{{item?.name}}</button>
        </div>
    </div>
</div>