import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {EventConfirmPopUP , UserStatusConfirmPopUP , ActiveConfirmPopUP} from '../../../../app/shared/pop-ups/confirmation-popup/confirmation-popup.model'
import { AppUserListService } from 'src/app/components/app-user-list/app-user-list.service';
@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  eventConfirmPopUP = EventConfirmPopUP;
  userStatusconfirmPopUP = UserStatusConfirmPopUP;
  activeConfirmPopup = ActiveConfirmPopUP;
  @Input() activeuser


  modalTitle : string;

  showEventConfirmationpopup : boolean = false;
  showuserStatusConfirmationpopup : boolean = false;
  showActiveConfirmationpopup : boolean = false;
  eventCountStatusRes = {};
  userId
  constructor(public activeModal:NgbActiveModal , private appUserListService :AppUserListService ){
  }


  ngOnInit(): void {
    if(this.modalTitle == this.userStatusconfirmPopUP.SUSPEND_HOST) this.fetchStatuswiseUserEventCount();
  }

  fetchStatuswiseUserEventCount(){
    this.appUserListService.getStatuswiseUserEventCount(this.userId).subscribe({
      next:(res)=>{
        if(res?.isSuccess) this.eventCountStatusRes = res?.response;
      },error:(err)=>{
        console.log(err)
      }
    })
  }


  onYesClicked() {
    // Handle 'Yes' button click logic here
    // This is where you can put the logic for 'activeUser' or 'InActiveUser'
    // ...

    // Close the modal with 'true'
    this.activeModal.close(true);
  }

  onCancelClicked(event: Event) {
    // Handle 'Cancel' button click logic here
    if (event.target instanceof HTMLInputElement) {
      // Set checkbox to unchecked
      event.target.checked = false;
    }
  
    // Close the modal without setting a result
    this.activeModal.close();
  }
}
