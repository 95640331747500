import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";

// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ButtonComponent } from './common-components/button/button.component';
import { CheckboxComponent } from './common-components/checkbox/checkbox.component';
import { InputComponent } from './common-components/input/input.component';
import { SettingComponent } from './components/header/elements/setting/setting.component';
import { OnlyNumbersDirective } from './directive/only-numbers.directive';
import { VectormapDirective } from './directive/vector-map.directive';
import { OnlyAlphabetsDirective } from './directive/only-alphabets.directive';
import { MinTimeValidatorDirective } from './directive/min-time-validator.directive';
import { ConditionalPasswordConfirmationDirective } from './directive/conditional-password-confirmation.directive';
import { MatchPasswordDirective } from './directive/match-password.directive';
import { CustomDropdownComponent } from './common-components/custom-dropdown/custom-dropdown.component';
import { UtcToLocalPipe } from './custom-common-pipe/utc-to-local.pipe';
import { DropdownComponent } from './common-components/dropdown/dropdown.component';
import { RangeDatpickerComponent } from './common-components/range-datpicker/range-datpicker.component';
import { ConfirmationPopupComponent } from './pop-ups/confirmation-popup/confirmation-popup.component';
import { SearchDropdownComponent } from './common-components/search-dropdown/search-dropdown.component';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';
import { EventRequestModule } from "../components/event-request/event-request.module";
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SvgIconComponent,
    SwiperComponent,
    ButtonComponent,
    CheckboxComponent,
    InputComponent,
    SettingComponent,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    ConditionalPasswordConfirmationDirective,
    MatchPasswordDirective,
    CustomDropdownComponent,
    UtcToLocalPipe,
    DropdownComponent,
    RangeDatpickerComponent,
    ConfirmationPopupComponent,
    SearchDropdownComponent,
    NotificationSidebarComponent,
    // MinTimeValidatorDirective,
    // VectormapDirective
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule, SwiperModule ,NgxDatatableModule],
  providers: [NavService, LayoutService, DecimalPipe],
  exports: [NgbModule, FormsModule, ReactiveFormsModule, LoaderComponent, BreadcrumbComponent, FeatherIconsComponent, OnlyAlphabetsDirective,RangeDatpickerComponent,
    OnlyNumbersDirective, TapToTopComponent, SvgIconComponent, SwiperModule ,NgxDatatableModule ,ButtonComponent,CustomDropdownComponent,CheckboxComponent,InputComponent,ConditionalPasswordConfirmationDirective,MatchPasswordDirective,UtcToLocalPipe,DropdownComponent,SearchDropdownComponent ],
})
export class SharedModule {}
