<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6 font-inter-700 d-flex">
        <span *ngIf="displayonReports"><h3 class="d-flex align-items-center">Report:&nbsp;</h3></span><h3 class="d-flex align-items-center">{{ title }} <span *ngIf="isVisibleBackLabel" (click)="backToPreviousPage.emit()" class="ms-2 custom-f-14 font-inter-600 cursor-pointer">(Back)</span></h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <!-- <app-svg-icon [icon]="'home'"></app-svg-icon> -->
              <svg width="0.875rem" height="0.938rem" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1695_3158)">
                <path d="M13.4873 5.79023L9.06268 1.36507C8.51507 0.81906 7.77331 0.512451 7.00001 0.512451C6.22671 0.512451 5.48495 0.81906 4.93734 1.36507L0.512757 5.79023C0.349672 5.95227 0.220373 6.14507 0.132355 6.35746C0.0443376 6.56984 -0.000647734 6.79758 7.04637e-06 7.02748V12.7541C7.04637e-06 13.2182 0.184382 13.6633 0.51257 13.9915C0.840759 14.3197 1.28588 14.5041 1.75001 14.5041H12.25C12.7141 14.5041 13.1593 14.3197 13.4874 13.9915C13.8156 13.6633 14 13.2182 14 12.7541V7.02748C14.0007 6.79758 13.9557 6.56984 13.8677 6.35746C13.7796 6.14507 13.6503 5.95227 13.4873 5.79023ZM8.75001 13.3374H5.25001V11.0426C5.25001 10.5784 5.43438 10.1333 5.76257 9.80513C6.09076 9.47694 6.53588 9.29257 7.00001 9.29257C7.46414 9.29257 7.90926 9.47694 8.23745 9.80513C8.56563 10.1333 8.75001 10.5784 8.75001 11.0426V13.3374ZM12.8333 12.7541C12.8333 12.9088 12.7719 13.0571 12.6625 13.1665C12.5531 13.2759 12.4047 13.3374 12.25 13.3374H9.91667V11.0426C9.91667 10.269 9.60938 9.52715 9.0624 8.98017C8.51542 8.43319 7.77356 8.1259 7.00001 8.1259C6.22646 8.1259 5.48459 8.43319 4.93761 8.98017C4.39063 9.52715 4.08334 10.269 4.08334 11.0426V13.3374H1.75001C1.5953 13.3374 1.44692 13.2759 1.33753 13.1665C1.22813 13.0571 1.16667 12.9088 1.16667 12.7541V7.02748C1.16721 6.87289 1.22862 6.72472 1.33759 6.61507L5.76218 2.19165C6.091 1.86436 6.53606 1.68062 7.00001 1.68062C7.46395 1.68062 7.90902 1.86436 8.23784 2.19165L12.6624 6.61682C12.771 6.72604 12.8323 6.87349 12.8333 7.02748V12.7541Z" fill="#00091A"/>
                </g>
                <defs>
                <clipPath id="clip0_1695_3158">
                <rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items" [ngClass]="{'d-none':item == null}">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>