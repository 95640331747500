import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Dropdown } from '../../common-model/common.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class DropdownComponent implements OnInit  {

  @Input() list = [];
  @Input() name:string;
  @Input() isRequired :boolean = false;
  dropdown = Dropdown;
  @Input() dropdownComp=this.dropdown.sapphire50Dropdown;
  @Input() label = 'Reason for rejection';
  @Output() selectedItem = new EventEmitter();
  // selectedValue  = '';
  @Input() selectedValue:any;
  displayName:any;

  selectOption(item){
    this.selectedItem.emit(item?.id);
    // this.selectedValue = item?.name;
  }

  ngOnChanges(changes: SimpleChanges){
    if(this.list?.length){
      this.displayName = this?.list?.find((x)=>x?.id == this?.selectedValue)?.name;
    }
  }

  ngOnInit(): void {
  }
}
