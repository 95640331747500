import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {
  transform(dateString: string): string {
    if (!dateString) {
      return 'Invalid Date';
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    try {
      const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };

      const formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);
      return formattedTime;
    } catch (error) {
      console.error('Error occurred while processing date:', error);
      return 'Error';
    }
  }
}
