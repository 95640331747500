import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { Observable, tap } from 'rxjs';
import { ResponseModel } from '../../shared/common-model/common.model';
import { GetCitiesRequestModel, InsertEditLookupValue, InsertLookUpValue } from './master-form-settings.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StoredCityList } from 'src/app/shared/common';

@Injectable({
  providedIn: 'root'
})
export class MasterFormSettingsService {

  constructor(private service : DataService , public storageService : StorageService) { }

  getMasterListForAdmin(): Observable<ResponseModel> {
    let url = "App/Admin/master/getAll";
    return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
            return response;
        })
    );
}

getMasterList(): Observable<ResponseModel> {
  let url = "App/Master/get";
  return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
          return response;
      })
  );
}

insertMasterValues(insertLookUpValue : InsertLookUpValue): Observable<ResponseModel> {
  let url = "App/Master/add";
  return this.service.post(url,insertLookUpValue).pipe<ResponseModel>(
      tap((response: any) => {
          return response;
      })
  );
}

fileUpload(formData): Observable<ResponseModel> {

  for (let [key, val] of (formData as any).entries()) {
    console.log(key, val);
  }
  let url = "resource/File/upload";
  return this.service.post(url,formData).pipe<ResponseModel>(
      tap((response: any) => {
          return response;
      })
  );
}

editMasterValues(insertEditLookupValue : InsertEditLookupValue , id:string): Observable<ResponseModel> {
  let url = `App/Master/${id}/update`;
  return this.service.patch(url,insertEditLookupValue).pipe<ResponseModel>(
      tap((response: any) => {
          return response;
      })
  );
}

getCities(getCitiesRequestModel : GetCitiesRequestModel): Observable<ResponseModel> {
  let url = "App/Master/getcities";
  return this.service.post(url,getCitiesRequestModel).pipe<ResponseModel>(
      tap((response: any) => {
          return response;
      })
  );
}

}
