export class Auth {
}

export class LoginRequestModel {
    email           : string;
    password        : string;
    rememberMe      : boolean=true;
    timeZone        : string="";
    deviceToken     : string="";
    // platformId      : number=1;

    // for verifcation 
    otpToken        : string;
    login2FAToken   : string;
    otp             : string;

  }

  export class Response {
    token           : Token;
    user            : User;
  }
  
  export class Token {
    accessToken     : string
    scope           : string
    tokenType       : string
    refreshToken    : string
    expiresIn       : number
  }
  
  export class User {
    id              : string;
    username        : string;
    email           : string;
    phoneNumber     : any;
    firstName       : string;
    lastName        : string;
    fullName        : string;
    profileImage    : ProfileImage;
    occupation      : any;
    industryId      : number;
    industry        : string;
    ageRangeId      : number;
    ageRange        : string;
    userStatusId    : number;
    userStatus      : string;
    timeZone        : string;
    deviceToken     : any;
    appThemeId      : number;
    appThemeName    : string;

    userRole    : string;
  }
  
  export class ProfileImage {
    imageUrl        : string;
    thumbnailUrl    : string;
  }

  export class SendOtpRequestModel {
    email: string;
    isAdminWebRequest: boolean = true;
  }

  export class OtpDigits{
    Otpdigit1       :string;
    Otpdigit2       :string;
    Otpdigit3       :string;
    Otpdigit4       :string;
    Otpdigit5       :string;
    Otpdigit6       :string;
  }

  export class ResetPassRequestModel {
    email           : string;
    newPassword     : string;
    conformPassword ?: string; //extra added
    resetPasswordToken: string;
  }
  
  export class VerifyOtpRequestModel {
    otpToken: string
    otp: string
  }


  export class ResetPasswordSendotpRequestModel {
    email: string;
    token : string;
  }

  export enum PassWordChangeRequest {
    ForgotPassword = 1,
    ResetPassword  = 2,
    ForcefullyResetPassword = 3
  }
  