import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';

@Directive({
  selector: '[appConditionalPasswordConfirmation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ConditionalPasswordConfirmationDirective,
      multi: true
    }
  ]
})
export class ConditionalPasswordConfirmationDirective implements Validator {
  @Input('appConditionalPasswordConfirmation') shouldApply: boolean;

  validate(control: AbstractControl): ValidationErrors | null {
    console.log(this.shouldApply)
    if (!this.shouldApply) {
      return null; // If shouldApply is false, do not apply validation
    }

    const parentFormGroup = control.parent as FormGroup;
    if (!parentFormGroup) {
      return null;
    }

    const password = parentFormGroup.get('passwordmain').value;
    const confirmPassword = parentFormGroup.get('confirmpasswordmain').value;
    console.log('pass', password , 'confirm',confirmPassword )

    if (confirmPassword !== password) {
      return { confirmPassword: true };
    }

    return null;
  }
}
